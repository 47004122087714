import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useMutation, useQuery } from 'urql'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { ConnectedFocusError } from 'focus-formik-error'

import { NoDiseaseDataSVG } from 'assets/svg'
import { ElementComponent, ModalComponent } from 'components'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'

import { GeneralDieaseForm, SpecificDiseaseForm } from './index'

interface DiseaseDataSpeicific {
  disease: {
    dId: string
    thaiName: string
  }
  date: Date
}

function HealthForm() {
  const navigate = useNavigate()
  const [, executeMedical] = useMutation(MedicalMutate.creatMedicalData)
  const [medicalQueryData] = useQuery(
    MedicalQuery.getDisease({
      AND: [
        { type: { equals: 'GENERAL' } },
        { diseaseAttibute: { every: { inputFlag: { equals: true } } } },
      ],
    }),
  )

  const [formData, setFormData] = useState({})
  const [validationForm, setValidationForm] = useState<any>({})

  const [diseaseList, setDiseaseList] = useState([])

  const [openDiseaseModal, setOpenDiseaseModal] = useState<boolean>(false)
  const [diseaseSpecific, setDiseaseSpecific] = useState([])

  const handleRemoveDiseaseSpecific = (index: number) => {
    let newDiseaseSpecific = [...diseaseSpecific]
    newDiseaseSpecific.splice(index, 1)
    setDiseaseSpecific(newDiseaseSpecific)
  }

  const handleSubmit = async (values?: any) => {
    try {
      const data = Object.keys(values).flatMap((key: any) => {
        if (
          values[key].value !== '' &&
          values[key].value !== null &&
          values[key].value.length !== 0
        ) {
          let result = {
            daId: values[key].daId,
            createdAt: values[key]?.createdAt ?? dayjs().toISOString(),
          }

          if (values[key].type === 'FLOAT')
            result['value'] = Number(values[key].value)
          else if (values[key].type === 'IMAGE' && values[key].value.length > 0)
            result['file'] = values[key].value
          else if (values[key].type === 'TEXT' && values[key].value !== '')
            result['desciption'] = values[key].value

          return result
        }

        return null
      })

      await executeMedical({
        data: {
          patientUser: {
            connect: {
              uId: '',
            },
          },
          submitUser: {
            connect: {
              uId: '',
            },
          },
          dataInputFlag: true,
          userMedTrasaction: {
            createMany: {
              data: data.filter((item) => item !== null),
            },
          },
        },
      })
      navigate({ search: '?upload=success' })
    } catch (e) {
      //console.log(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!medicalQueryData.fetching) {
        const { getDiseases } = await medicalQueryData.data
        const form = getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            diseaseList[data.medName] = {
              type: data.type,
              daId: data.daId,
              value: data.type === 'IMAGE' ? [] : '',
            }
          })
          return diseaseList
        }, {})

        const validation = await getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            if (data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .required(`กรุณากรอก${data.thaiName}`)
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value) {
                        return value.toString().match(/^\d+(\.\d{0,2})?$/)
                          ? true
                          : false
                      }
                      return true
                    },
                  ),
              })
            }

            if (data.type === 'FLOAT' && !data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value) {
                        return value.toString().match(/^\d+(\.\d{0,2})?$/)
                          ? true
                          : false
                      }
                      return true
                    },
                  ),
              })
            }
          })
          return diseaseList
        }, {})

        setFormData(form)
        setDiseaseList(getDiseases)
        setValidationForm(yup.object().shape({ ...validation }))
      }
    })()
  }, [medicalQueryData])

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validationSchema={validationForm}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <ConnectedFocusError />
          <div
            className="rounded-[10px] px-[18px] pt-[18px] pb-[30px] mt-[25px] bg-white-main"
            style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
          >
            <p className="py-[13px] px-[18px] mb-[40px] bg-gray-light rounded-[5px] text-[18px] font-bold">
              ข้อมูลทั่วไป
            </p>

            <GeneralDieaseForm formData={formik} diseaseList={diseaseList} />
          </div>

          <div
            className="rounded-[10px] px-[18px] pt-[18px] pb-[30px] mt-[25px] bg-white-main"
            style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex justify-between items-center py-[13px] px-[10px] laptop:px-[18px] mb-[40px] bg-gray-light rounded-[5px]">
              <p className="text-[14px] laptop:text-[18px] font-bold">
                ข้อมูลเฉพาะทาง
              </p>

              <div className="bg-white-main shrink">
                <ElementComponent.Button
                  id="add-disease-specific"
                  icon="tabler:plus"
                  shape="outlined"
                  text="เพิ่มข้อมูลเฉพาะทาง"
                  width="100%"
                  onAction={() => setOpenDiseaseModal(true)}
                />
              </div>
            </div>

            {diseaseSpecific.length >= 1 ? (
              <div className="flex flex-col gap-[20px]">
                {diseaseSpecific.map((value, index: number) => (
                  <SpecificDiseaseForm
                    key={`diseaseSpecific-form-${index}`}
                    data={value}
                    formData={formik}
                    onRemove={() => handleRemoveDiseaseSpecific(index)}
                    onSetFormData={(newField: any) => {
                      setFormData({ ...formik.values, ...newField })
                    }}
                    onSetValidateForm={(newSchema: any) =>
                      setValidationForm(
                        yup
                          .object()
                          .shape({ ...validationForm.fields, ...newSchema }),
                      )
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <NoDiseaseDataSVG />
                <p className="mt-[30px]">ตอนนี้คุณยังไม่มีข้อมูลโรคแสดง</p>
              </div>
            )}

            <ModalComponent.SpecificDataModal
              diseaseList={diseaseSpecific.map(
                (values: DiseaseDataSpeicific) => {
                  return values.disease.dId
                },
              )}
              open={openDiseaseModal}
              onClose={() => setOpenDiseaseModal(false)}
              onSelect={(values) => {
                setDiseaseSpecific([...diseaseSpecific, values])
              }}
            />
          </div>

          <Stack
            direction={{ mobile: 'column', laptop: 'row' }}
            justifyContent={{ mobile: 'center', laptop: 'end' }}
            className="mt-[50px]"
          >
            <ElementComponent.Button
              id="submit-health-form"
              submit
              text="ยืนยันส่งข้อมูล"
              width="100%"
              height="52px"
            />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default HealthForm
