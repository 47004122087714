export const getUser = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            prefix
                            firstname
                            lastname
                            imagePath
                            UserAuth {
                              phoneNumber
                            }
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                              province
                              district
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}

export const getUserProfile = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            firstname
                            imagePath
                            hnId
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}
