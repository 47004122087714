import React from 'react'
import { useRoutes, Navigate, BrowserRouter } from 'react-router-dom'

import { NavComponent } from 'components'
import { route } from 'settings'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import {
  MainPages,
  RegisterPages,
  LoginPages,
  ForgetPages,
  ProfilePages,
  OverviewPages,
  ConsultingPages,
  HealthDataPages,
} from 'pages'

function RoutePage() {
  const { isLaptop } = useResponsive()

  const auth = general.auth.isAuthorized()

  const redirectCondition = (condition: boolean, children: React.ReactNode) => {
    if (condition) return children
    else return <Navigate replace to={route.LANDING_PAGE_URL} />
  }

  return useRoutes([
    {
      path: route.LANDING_PAGE_URL,
      element: <NavComponent.MainLayout />,
      children: [
        { path: route.LANDING_PAGE_URL, element: <MainPages.Main /> },
        {
          path: route.LOGIN_PAGE_URL,
          element: redirectCondition(!auth && isLaptop, <LoginPages.Login />),
        },
        {
          path: route.FORGET_PASSWORD_PAGE_URL,
          element: <ForgetPages.Forget />,
        },
        {
          path: route.REGISTER_PAGE_URL,
          element: redirectCondition(
            !auth || window.location.search === '?register=success',
            <RegisterPages.Register />,
          ),
        },
        {
          path: route.CONSULTING_FORM_PAGE_URL,
          element: <ConsultingPages.Consulting />,
        },
        {
          path: route.PROFILE_PAGE_URL,
          element: redirectCondition(auth, <ProfilePages.Profile />),
        },
        {
          path: route.OVERVIEW_PAGE_URL,
          element: <NavComponent.UserLayout />,
          children: [
            {
              path: route.OVERVIEW_PAGE_URL,
              element: redirectCondition(auth, <OverviewPages.OverviewPage />),
            },
          ],
        },
        {
          path: route.HEALTH_PAGE_URL,
          element: <NavComponent.UserLayout />,
          children: [
            {
              path: route.HEALTH_PAGE_URL,
              element: redirectCondition(
                auth,
                <OverviewPages.HealthTablePage />,
              ),
            },
            {
              path: route.UPLOAD_HEALTH_PAGE_URL,
              element: redirectCondition(
                auth,
                <OverviewPages.HealthSection.UploadImageSection />,
              ),
            },
            {
              path: route.CREATE_HEALTH_PAGE_URL,
              element: redirectCondition(
                auth,
                <OverviewPages.HealthSection.CreateHealthSection />,
              ),
            },
            {
              path: route.USER_HEALTH_DATA_PAGE_URL,
              element: redirectCondition(
                auth,
                <HealthDataPages.UserHealthData />,
              ),
            },
            {
              path: route.DIAGNOSIS_HEALTH_DATA_PAGE_URL,
              element: redirectCondition(
                auth,
                <HealthDataPages.UserHealthData diagnosis />,
              ),
            },
          ],
        },
      ],
    },
    {
      path: route.EXPECT_PAGE_URL,
      element: <NavComponent.MainLayout />,
    },
  ])
}

const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <RoutePage />
    </BrowserRouter>
  )
}

export default Router
