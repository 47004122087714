import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useRecoilState } from 'recoil'
import { useMutation } from 'urql'
import { Menu, MenuItem, Skeleton } from '@mui/material'

import { DrawerComponent, ElementComponent } from 'components'
import { AuthMutate } from 'services/graphql/authService'
import { LogoSVG, AvatarProfile } from 'assets/svg'
import { appSettings, route } from 'settings'
import { general } from 'utils'
import { useUser, useSystem } from 'stores/recoil'

interface UserMenuListProps {
  open: boolean
  anchorEl: HTMLElement | null
  onCloseMenu: Function
}

function UserMenuList({ open, anchorEl, onCloseMenu }: UserMenuListProps) {
  const [, executeMutation] = useMutation(AuthMutate.logout)

  const userMenuList: { title: string; path: string; icon: string }[] = [
    {
      title: 'ข้อมูลบัตรคนรักสุขภาพ',
      path: route.OVERVIEW_PAGE_URL,
      icon: 'system-uicons:document-justified',
    },
    {
      title: 'ข้อมูลสุขภาพ',
      path: route.HEALTH_PAGE_URL,
      icon: 'ri:shield-cross-line',
    },
    {
      title: 'บัญชีของฉัน',
      path: route.PROFILE_PAGE_URL,
      icon: 'ic:baseline-person',
    },
  ]

  const logoutUser = async () => {
    await executeMutation({
      data: {
        uId: '',
        refresh_token: general.cookie.getCookie(appSettings.TOKEN_KEY),
      },
    })
    general.auth.logout()
    general.http.redirect()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => onCloseMenu()}
      className="mt-[10px]"
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {userMenuList.map(
        (
          item: { title: string; path: string; icon: string },
          index: number,
        ) => (
          <NavLink
            key={`user-menu-${index}`}
            className={({ isActive }) =>
              isActive
                ? 'text-blue-main transition-all ease-in-out'
                : 'transition-all ease-in-out text-black-main'
            }
            style={{ display: 'flex', alignItems: 'center' }}
            to={item.path}
            onClick={() => onCloseMenu()}
          >
            <MenuItem sx={{ width: '100%' }}>
              <Icon icon={item.icon} className="text-[25px] mr-[5px]" />
              <p>{item.title}</p>
            </MenuItem>
          </NavLink>
        ),
      )}

      <MenuItem onClick={logoutUser}>
        <div className="flex items-center gap-[10px]">
          <Icon
            icon="material-symbols:logout-rounded"
            className="text-[25px]"
          />
          <p>ออกจากระบบ</p>
        </div>
      </MenuItem>
    </Menu>
  )
}

function NavbarMobile() {
  const location = useLocation()
  const isProfile = Boolean(location.pathname.includes(route.PROFILE_PAGE_URL))
  const isOverview = Boolean(
    location.pathname.includes(route.OVERVIEW_PAGE_URL),
  )
  const isHealthData = Boolean(
    location.pathname.includes(route.HEALTH_PAGE_URL),
  )

  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [imageStateError, setImageStateError] = useState<boolean>(false)

  const [openMenu, setOpenMenu] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerSideMeueStateAtom,
  )
  const [openLoginBar, setOpenLoginBar] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerLoginStateAtom,
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const imageOnError = () => {
    setImageStateError(true)
  }

  useEffect(() => {
    setOpenMenu(false)
    setOpenLoginBar(false)
    setAnchorEl(null)
  }, [location])

  const onClickOpenLoginBar = () => {
    if (openLoginBar && openMenu) {
      setOpenMenu(false)
      setOpenLoginBar(true)
      return
    }
    if (openMenu) setOpenMenu(false)
    setOpenLoginBar(!openLoginBar)
  }

  const renderName = (name: string) => {
    if (name.length > 14) {
      return `${name.substring(0, 14)}...`
    }
    return name
  }

  useEffect(() => {
    if (user['imagePath']) setImageStateError(false)
  }, [user])

  return (
    <>
      <div
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, .1)' }}
        className="fixed top-0 left-0 z-[1300] w-full h-[90px] px-[20px] laptop:px-[30px] bg-white-main flex justify-between items-center"
      >
        <div className="flex items-center gap-[0px]">
          {openMenu ? (
            <Icon
              id="close-nabvar-mobile"
              icon="akar-icons:cross"
              className="text-[25px]"
              onClick={() => setOpenMenu(false)}
            />
          ) : (
            <Icon
              id="menu-nabvar-mobile"
              icon="material-symbols:menu"
              className="text-[35px]"
              onClick={() => setOpenMenu(true)}
            />
          )}

          <Link to={route.LANDING_PAGE_URL}>
            <LogoSVG className="w-[65px] h-[65px] laptop:w-[70px] laptop:h-[70px] " />
          </Link>
        </div>

        {general.auth.isAuthorized() ? (
          user['firstname'] ? (
            <div className="flex items-center gap-[10px]">
              {!imageStateError && user['imagePath'] ? (
                <ElementComponent.Image
                  fullImage={false}
                  className="h-[40px] w-[40px] laptop:h-[30px] laptop:w-[30px] rounded-full bg-cover bg-center"
                  src={user['imagePath']}
                  onError={imageOnError}
                />
              ) : (
                <AvatarProfile className="h-[30px] w-[30px] rounded-full bg-cover bg-center" />
              )}
              <h1 className="overflow-hidden whitespace-pre text-ellipsis">
                {renderName(user['firstname'])}
              </h1>
              <div
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  setAnchorEl(event.currentTarget)
                }
              >
                <Icon
                  icon="ic:baseline-keyboard-arrow-down"
                  className="text-[20px] cursor-pointer"
                />
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-[10px]">
              <div className="flex flex-row gap-2">
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton variant="text" width={80} height={20} />
              </div>
            </div>
          )
        ) : (
          <div
            className="flex items-center gap-[10px] cursor-pointer"
            onClick={onClickOpenLoginBar}
          >
            <Icon icon="bi:person-circle" className="text-[30px]" />
            <p className="text-[18px]">เข้าสู่ระบบ</p>
          </div>
        )}
      </div>

      <UserMenuList
        open={open}
        anchorEl={anchorEl}
        onCloseMenu={() => {
          setAnchorEl(null)
          setOpenMenu(false)
        }}
      />

      {isProfile || isOverview || isHealthData ? (
        <DrawerComponent.UserProfileBar
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        />
      ) : (
        <DrawerComponent.NavbarMenuBar
          open={openMenu}
          onClose={() => setOpenMenu(false)}
        />
      )}

      <DrawerComponent.LoginBar
        open={openLoginBar && !general.auth.isAuthorized()}
        onClose={() => setOpenLoginBar(false)}
      />
    </>
  )
}

export default NavbarMobile
