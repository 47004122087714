import React from 'react'
import { Icon } from '@iconify/react'

import { ModalForm } from 'components/element'

interface ResetPassModalProps {
  phone: string
  password: string
  open: boolean
  onClose: Function
}

function ResetPassModal({
  phone,
  password,
  open,
  onClose,
}: ResetPassModalProps) {
  return (
    <ModalForm open={open} onClose={() => onClose()}>
      <div className="flex flex-col items-center text-[18px] py-[52px] w-screen laptop:max-w-[600px] max-w-[450px]">
        <Icon
          icon="material-symbols:check-circle-rounded"
          className="w-[70px] h-[70px] mb-[20px] text-[#3BB54A]"
        />
        <p className="mb-[15px] text-[20px] font-[600]">
          เปลี่ยนรหัสผ่านสำเร็จ
        </p>
        <p className="mb-[32px] text-red-main px-[40px] break-words text-center">
          หมายเหตุ * กรุณาจดบันทึกรหัสนี้ หรือ ทำการบันทึกหน้าจอไว้
        </p>

        <div className="flex justify-center gap-[10px]">
          <div className="flex flex-col font-[600] gap-[10px]">
            <p>ชื่อผู้ใช้งาน :</p>
            <p>รหัสผ่าน :</p>
          </div>
          <div className="flex flex-col gap-[10px]">
            <p>{phone}</p>
            <p>{password}</p>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

export default ResetPassModal
