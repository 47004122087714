import React from 'react'
import { Drawer, Stack, Divider } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { general } from 'utils'
import { route } from 'settings'

interface NavbarMenuProps {
  open: boolean
  onClose: Function
}

interface MenuItemProps {
  name: string
  text: string
  path: string
  displayCondition: boolean
  onAction: Function
}

const menuList = [
  {
    name: 'card',
    text: 'บัตรคนรักสุขภาพ',
    path: '/?section=usercard',
    auth: true,
    spacificCheck: true,
  },
  {
    name: 'doctor',
    text: 'ปรึกษาหมอ',
    path: '/?section=consulting',
    auth: true,
    spacificCheck: true,
  },
  {
    name: 'community',
    text: 'ชุมชนคนรักสุขภาพ',
    path: '/?section=comunity',
    auth: true,
    spacificCheck: true,
  },
  {
    name: 'register',
    text: 'สมัครสมาชิก',
    path: route.REGISTER_PAGE_URL,
  },
]

function LinkItem({
  name,
  text,
  path,
  displayCondition,
  onAction,
}: MenuItemProps) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive && displayCondition
          ? 'text-blue-main transition-all ease-in-out'
          : 'transition-all ease-in-out text-black-main'
      }
      onClick={() => onAction()}
    >
      <div className="flex items-center gap-[5px]">
        <p id={`link-${name}`} className="text-[20px]">
          {text}
        </p>
      </div>
    </NavLink>
  )
}

export default function NavbarMenu({ open, onClose }: NavbarMenuProps) {
  return (
    <Drawer anchor="top" open={open} onClose={() => onClose()}>
      <Stack
        direction="column"
        spacing="20px"
        divider={<Divider />}
        className="w-full h-screen px-[18px] py-[20px] my-[90px] text-[18px]"
      >
        {menuList.map((item, index: number) => {
          if (general.auth.isAuthorized()) {
            if (item.auth) {
              return (
                <div className="mt-[10px]" key={`navber-menu-${index}`}>
                  <LinkItem
                    name={item.name}
                    text={item.text}
                    path={item.path}
                    onAction={() => onClose()}
                    displayCondition={
                      item.spacificCheck
                        ? item.path === `/${location.search}`
                        : true
                    }
                  />
                </div>
              )
            } else return null
          }

          return (
            <div className="mt-[10px]" key={`navber-menu-${index}`}>
              <LinkItem
                name={item.name}
                text={item.text}
                path={item.path}
                onAction={() => onClose()}
                displayCondition={
                  item.spacificCheck
                    ? item.path === `/${location.search}`
                    : true
                }
              />
            </div>
          )
        })}
      </Stack>
    </Drawer>
  )
}
