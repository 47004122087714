import React from 'react'
import { Typography, Stack } from '@mui/material'

import { Survey1, Survey2, Survey3 } from 'assets/picture'
import { ElementComponent } from 'components'

function AsaNew() {
  return (
    <div className="text-center h-auto pt-[60px] pb-[100px] bg-white-main">
      <div className="flex justify-center px-[50px]">
        <div className="w-full max-w-[700px] laptop:max-w-[900px]">
          <Typography variant="subtitle1">
            <p className="font-bold mb-[20px]">อาสาสุขภาพ</p>
          </Typography>
          <Typography variant="subtitle2">
            ร่วมเป็นส่วนนึงในการเป็นสะพานบุญให้กับผู้ขาดโอกาส ด้วยเทคโนโลยี
            ในการจัดการบริหารและคัดกรองของบริจาคให้ตรงตามความต้องการของผู้ขาดโอกาส
          </Typography>
        </div>
      </div>

      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        className="gap-[18px] mt-[45px] laptop:mt-[60px] px-[30px] laptop:h-[300px] justify-center items-center"
      >
        <div className="w-[95%] laptop:w-[30%] laptop:h-full bg-cover">
          <ElementComponent.Image
            fullImage={false}
            src={Survey1}
            className="rounded-[15px] w-full h-full cursor-default"
          />
        </div>
        <div className="w-[95%] laptop:w-[30%] laptop:h-full">
          <ElementComponent.Image
            fullImage={false}
            src={Survey2}
            className="rounded-[15px] w-full h-full cursor-default"
          />
        </div>
        <div className="w-[95%] laptop:w-[30%] laptop:h-full">
          <ElementComponent.Image
            fullImage={false}
            src={Survey3}
            className="w-full h-full rounded-[15px] cursor-default"
          />
        </div>
      </Stack>
    </div>
  )
}

export default AsaNew
