import React, { useEffect, useState, useRef } from 'react'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import dayjs from 'dayjs'

import { RegisterSuccessSVG } from 'assets/svg'
import { useAuth } from 'stores/recoil'
import { appSettings } from 'settings'
import { ElementComponent } from 'components'
import { general } from 'utils'
import { route } from 'settings'
import { useResponsive } from 'hooks'

function RegisterSuccessPage() {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()
  const contentRef = useRef<HTMLDivElement | null>(null)

  const [user, setUser] = useState({ username: '', password: '' })
  const [, setToken] = useRecoilState(useAuth.selector.selectorToken)

  const getRegisterAccount = () => {
    try {
      const { verifyRegister } = JSON.parse(
        general.cookie.getCookie(appSettings.AUTH_KEY),
      )

      if (verifyRegister) {
        setUser({
          username: verifyRegister.phoneNumber,
          password: verifyRegister.password,
        })

        general.cookie.setCookie(
          appSettings.TOKEN_KEY,
          verifyRegister.refreshToken,
        )
        setToken(verifyRegister.accessToken)

        general.cookie.setCookie(
          appSettings.CONSULTING_KEY,
          'ACCESS',
          dayjs().add(10, 'm').toDate(),
        )
      }
    } catch {
      general.http.redirect()
    }
  }

  const handleHeightContent = () => {
    if (contentRef) {
      const height = window.innerHeight - contentRef.current?.clientHeight

      if (height < 90) return 'mt-[90px]'
    }
  }

  useEffect(() => {
    // Run! Like go get some data from an API.
    getRegisterAccount()
  }, [])

  const handleConsultDoctor = () => {
    general.http.goto(route.CONSULTING_FORM_PAGE_URL)
  }

  return (
    <div className="laptop:bg-white-darker bg-white-main h-full relative overflow-auto">
      <div
        ref={contentRef}
        className={`py-[40px] px-[18px] flex flex-col items-center rounded-[15px] laptop:text-[18px] text-[16px] bg-white-main w-full laptop:max-w-[900px] absolute top-1/2 left-1/2 ${handleHeightContent()}`}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <p className="text-[32px] text-blue-main">ลงทะเบียนสำเร็จ</p>
        <RegisterSuccessSVG className="mt-[40px] mb-[30px] w-full" />
        <p>ระบบได้ทำการเปิดใช้งานบัญชีของคุณ เรียบร้อยแล้ว</p>
        <p className="text-red-main mt-[7px]">
          หมายเหตุ * กรุณาจดบันทึกรหัสนี้ หรือ ทำการบันทึกหน้าจอไว้
        </p>

        <div className="text-start mt-[20px]">
          <p>{`ชื่อผู้ใช้งาน : ${user.username}`}</p>
          <p>{`รหัสผ่าน : ${user.password}`}</p>
        </div>

        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          className="mt-[40px] gap-[20px] justify-center w-full max-w-[500px] p-[18px]"
        >
          <div className="order-2 laptop:order-1">
            <ElementComponent.Button
              id="back-to-home-button"
              height="52px"
              width={isMobile ? '100%' : '240px'}
              text="กลับสู่หน้าหลัก"
              shape="outlined"
              onAction={() => navigate(route.LANDING_PAGE_URL)}
            />
          </div>
          <div className="order-1 laptop:order-2">
            <ElementComponent.Button
              id={'consult-doctor-button'}
              height="52px"
              width={isMobile ? '100%' : '240px'}
              text="ปรึกษาหมอทันที"
              onAction={handleConsultDoctor}
            />
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default RegisterSuccessPage
