import React, { useState, useRef } from 'react'
import { Stack } from '@mui/material'
import { useQuery, useMutation } from 'urql'
import { Formik, Form } from 'formik'
import { ConnectedFocusError } from 'focus-formik-error'
import { Icon } from '@iconify/react'
import { useRecoilState } from 'recoil'
import Resizer from 'react-image-file-resizer'
import dayjs from 'dayjs'
import * as yup from 'yup'

import { UserQuery, UserMutate } from 'services/graphql/userService'
import { ProfileType } from 'types'
import { useResponsive } from 'hooks'
import { date } from 'utils'
import { useUser } from 'stores/recoil'
import { AvatarProfile } from 'assets/svg'

import {
  ElementComponent,
  FormComponent,
  AlertComponent,
  SkeletonComponent,
} from 'components'

interface UserProfileFormProps {
  data: any
  reExecuteQuery: Function
  onOpenModal: Function
  setErrorModalData: React.Dispatch<
    React.SetStateAction<{
      title: string
      description: string
      open: boolean
    }>
  >
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationProfile = yup.object({
  image: yup.string().nullable(),
  prefix: yup.string().required('กรุณาเลือกคำนำหน้า'),
  firstname: yup
    .string()
    .max(30, 'กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร'),
  lastname: yup
    .string()
    .max(30, 'กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร'),
  gender: yup.string().required('กรุณาเลือกเพศ'),
  birthdate: yup
    .date()
    .test(
      'check-date-more-than-today',
      'วันเกิดไม่ครวเป็นวันในอนาคต',
      (value) => {
        if (dayjs().add(543, 'year').isBefore(dayjs(value))) return false
        return true
      },
    )
    .test(
      'check-date-more-less-than-130-year',
      'ต้องขออภัย ระบบไม่รองรับคนที่อายุมากกว่า 130 ปี',
      (value) => {
        if (dayjs(value) < dayjs().subtract(130, 'year')) return false
        return true
      },
    )
    .typeError(
      'รูปแบบของวันที่ไม่ถูกต้อง โปรดระบุในรูปแบบ วัน/เดือน/ปี และ ปีเป็น พศ.',
    )
    .nullable()
    .required('กรุณาเลือกวัน/เดือน/ปี เกิด'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 ตัวเลข'),
  province: yup.string().required('กรุณาเลือกจังหวัด'),
  district: yup.string().required('กรุณาเลือก อำเภอ/เขต'),
})

function UserInfomation({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      gap="5px"
      className="mt-[10px]"
    >
      {children}
    </Stack>
  )
}

function UserProfileForm({
  data,
  reExecuteQuery,
  onOpenModal,
  setErrorModalData,
}: UserProfileFormProps) {
  const { isMobile } = useResponsive()
  const uploadRef = useRef(null)
  const [uploadFile, setUploadFile] = useState<boolean>(false)
  const [imageStateError, setImageStateError] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [, setUser] = useRecoilState(useUser.seletor.seletorUser)

  const [, executeMutation] = useMutation(UserMutate.updateUser)

  const initialValues = {
    image: data?.imagePath ? data?.imagePath : '',
    prefix: data?.prefix ?? '',
    firstname: data?.firstname ?? '',
    lastname: data?.lastname ?? '',
    gender: data?.UserMedicalDemograhicData?.gender ?? '',
    birthdate:
      dayjs(data?.UserMedicalDemograhicData?.birthdate).toDate() ?? null,
    phone: data?.UserAuth?.phoneNumber ?? '',
    district: data?.UserMedicalDemograhicData?.district ?? '',
    province: data?.UserMedicalDemograhicData?.province ?? '',
  }

  const handleImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    formData,
  ) => {
    const { files } = event.target

    if (files) {
      setImageStateError(false)
      setUploadFile(true)
      Resizer.imageFileResizer(
        files[0],
        200,
        200,
        'JPEG',
        100,
        0,
        (url) => formData.setFieldValue('image', url),
        'base64',
      )
    }
  }

  const updateUserInfo = async (values: ProfileType.UserInfoType) => {
    let submitData = {
      imagePath: {
        set: values.image.split(',')[1],
      },
      prefix: {
        set: values.prefix,
      },
      firstname: {
        set: values.firstname,
      },
      lastname: {
        set: values.lastname,
      },
      UserMedicalDemograhicData: {
        update: {
          birthdate: {
            set: dayjs(values.birthdate).toDate(),
          },
          gender: {
            set: values.gender,
          },
          district: {
            set: values.district,
          },
          province: {
            set: values.province,
          },
        },
      },
    }

    if (!uploadFile) {
      delete submitData.imagePath
    }

    await executeMutation({
      data: submitData,
      where: {
        uId: '',
      },
    }).then((res) => {
      if (res.error) {
        setErrorModalData({
          title: 'บันทึกข้อมูลไม่สำเร็จ',
          description: 'กรุณาทดลองใหม่อีกครั้ง',
          open: true,
        })
        return
      }

      setUser({
        firstname: res.data.updateUserInfo.firstname ?? '',
        imagePath: res.data.updateUserInfo.imagePath ?? '',
        hnId: res.data.updateUserInfo.hnId,
      })
      onOpenModal()
      setEditMode(false)
      window.scrollTo(0, 0)
      setUploadFile(false)
      reExecuteQuery()
    })
  }

  const imageOnError = () => {
    setImageStateError(true)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationProfile}
      enableReinitialize={true}
      onSubmit={updateUserInfo}
    >
      {(formik) => (
        <>
          <Form>
            <div
              className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px] laptop:p-[60px] text-[16px]"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <ConnectedFocusError />
              {!editMode && (
                <div
                  className="flex gap-[8px] items-center absolute top-[33px] right-[55px] cursor-pointer hover:text-blue-main"
                  onClick={() => setEditMode(!editMode)}
                >
                  <Icon icon="material-symbols:edit" />
                  <span>แก้ไข</span>
                </div>
              )}

              <div className="flex justify-center mt-[40px] mb-[45px]">
                <div className="relative flex justify-center items-center w-[150px] h-[150px] bg-white-dark border border-gray-light rounded-full">
                  {!imageStateError && formik.values.image !== '' ? (
                    <ElementComponent.Image
                      fullImage={false}
                      className="w-[150px] h-[150px] bg-center bg-cover rounded-full"
                      src={formik.values.image}
                      onError={imageOnError}
                    />
                  ) : (
                    <AvatarProfile className="w-[150px] h-[150px] bg-center bg-cover rounded-full" />
                  )}

                  <input
                    className="hidden"
                    ref={uploadRef}
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleImage(event, formik)}
                  />

                  {editMode && (
                    <div className="absolute right-[10px] bottom-0 bg-blue-main rounded-full w-[28px] h-[28px] flex justify-center items-center cursor-pointer">
                      <Icon
                        icon="material-symbols:edit"
                        className="text-white-main"
                        onClick={() => uploadRef.current.click()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {!editMode ? (
                <div className="flex flex-col gap-[10px]">
                  <UserInfomation>
                    <p className="w-[130px] font-bold">ชื่อ-นามสกุล :</p>
                    <p>{`${data?.prefix} ${data?.firstname} ${data?.lastname}`}</p>
                  </UserInfomation>

                  <UserInfomation>
                    <p className="w-[130px] font-bold">เพศ :</p>
                    <p>{data?.UserMedicalDemograhicData?.gender}</p>
                  </UserInfomation>

                  <UserInfomation>
                    <p className="w-[130px] font-bold">วัน/เดือน/ปี เกิด :</p>
                    <p>
                      {date.convertToShowDate(
                        data?.UserMedicalDemograhicData?.birthdate,
                      )}
                    </p>
                  </UserInfomation>

                  <UserInfomation>
                    <p className="w-[130px] font-bold">เบอร์โทรศัพท์ :</p>
                    <p>{data?.UserAuth?.phoneNumber}</p>
                  </UserInfomation>
                </div>
              ) : (
                <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto">
                  <FormComponent.RegisterForm.PersonForm
                    form={formik}
                    disable={{ phone: true }}
                    isLoading={false}
                  />
                </div>
              )}

              <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

              {!editMode ? (
                <>
                  <p className="font-bold text-[18px] mb-[20px]">
                    ข้อมูลที่อยู่
                  </p>
                  <div className="flex flex-col gap-[10px]">
                    <UserInfomation>
                      <p className="w-[130px] font-bold">จังหวัด :</p>
                      <p>{data?.UserMedicalDemograhicData?.province}</p>
                    </UserInfomation>

                    <UserInfomation>
                      <p className="w-[130px] font-bold">อำเภอ/เขต :</p>
                      <p>{data?.UserMedicalDemograhicData?.district}</p>
                    </UserInfomation>
                  </div>
                </>
              ) : (
                <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto mb-5">
                  <FormComponent.RegisterForm.AddressForm
                    form={formik}
                    isLoading={false}
                  />
                </div>
              )}
            </div>
            {editMode && (
              <div className="flex flex-col laptop:flex-row laptop:justify-end w-full mt-14">
                <ElementComponent.Button
                  id="submit-button"
                  submit
                  text="ยืนยันส่งข้อมูล"
                  width={isMobile ? '100%' : '260px'}
                  height="52px"
                />
              </div>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}

export default function UserProfile() {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [result, reExecuteQuery] = useQuery(UserQuery.getUser())

  const [errorModalData, setErrorModalData] = useState({
    title: 'บันทึกข้อมูลไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  return (
    <div className="w-full max-w-[860px]">
      <div className="flex items-center gap-[20px] mb-[18px]">
        <Icon icon="ic:baseline-person" className="text-[24px]" />
        <p className="text-[20px] font-bold">ข้อมูลส่วนตัว</p>
      </div>

      <div>
        {result.fetching || result.error ? (
          <SkeletonComponent.UserProfileSkeleton />
        ) : (
          <UserProfileForm
            data={result.data.getUser}
            reExecuteQuery={reExecuteQuery}
            onOpenModal={() => setOpenSuccessModal(true)}
            setErrorModalData={setErrorModalData}
          />
        )}
      </div>

      <AlertComponent.SuccessModal
        title="บันทึกข้อมูลเรียบร้อย"
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
      />

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}
