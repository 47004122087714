import React from 'react'
import { Typography } from '@mui/material'

import { AsaCard } from 'assets/picture'
import * as SVG from 'assets/svg'

interface CardComponentProps {
  icon: React.ReactNode
  title: string
  description: string
}

function CardComponent({ icon, title, description }: CardComponentProps) {
  return (
    <div
      className="
        bg-[#004F64] w-full max-w-[430px] h-[150px] laptop:h-[200px] p-[28px] 
        laptop:p-[30px] items-center rounded-[15px] text-white-main"
    >
      <div className="flex gap-[25px]">
        <div className="flex justify-start w-[80px] border:w-[100px] mt-3">
          {icon}
        </div>
        <div className="text-start">
          <div className="mb-[10px]">
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </div>
          <Typography variant="body1">{description}</Typography>
        </div>
      </div>
    </div>
  )
}

function UserCard() {
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-full mt-[60px] laptop:mt-[100px] desktop:mt-[165px]">
        <div className="absolute w-full bottom-[-1px] z-20">
          <SVG.CurveBodySVG />
        </div>
      </div>
      <div className="bg-white-main w-full text-center z-20">
        <div className="pt-[80px] p-[16px]">
          <div className="mb-[35px] laptop:mb-[70px]">
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              ให้ DR. ASA ดูแลคุณและคนที่คุณรัก
            </Typography>
          </div>

          <div className="flex flex-col laptop:flex-row gap-[10px] laptop:justify-center items-center laptop:items-start">
            <CardComponent
              icon={<SVG.DoctorSVG className="w-fit" />}
              title="ปรึกษาคุณหมอ"
              description="ปรึกษาคุณหมอเพื่อออกแบบสุขภาพให้เหมาะสม"
            />

            <CardComponent
              icon={<SVG.CalenderSVG className="w-fit" />}
              title="ติดตามสุขภาพ"
              description="ติดตามสุขภาพอย่างต่อเนื่อง"
            />

            <CardComponent
              icon={<SVG.CareSVG className="w-fit" />}
              title="ออกแบบสุขภาพ"
              description="ออกแบบสุขภาพให้เหมาะสมกับตัวคุณ"
            />
          </div>
        </div>

        <div
          id="usercard"
          className="mb-[100px] mt-[60px] laptop:mt-[100px] w-full m-0 text-center"
        >
          <div className="flex flex-col items-center px-[30px]">
            <div className="mb-[40px] laptop:mb-[80px] w-full max-w-[900px]">
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', marginBottom: '25px' }}
              >
                บัตรคนรักสุขภาพ
              </Typography>
              <Typography variant="subtitle2">
                บัตรประจำตัว
                วิเคราะห์สุขภาพอย่างตรงจุดติดตามสุขภาพอย่างต่อเนื่องปรับแต่งการรักษาให้เหมาะสม
                ด้วยฟีเจอร์บัตรดูเเลสุขภาพที่เสมือนสมุดสุขภาพประจำตัวคุณ
              </Typography>
            </div>
            <img src={AsaCard} style={{ width: '676px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCard
