import React from 'react'
import { Modal } from '@mui/material'
import { Icon } from '@iconify/react'

interface ModalProps {
  open: boolean
  onClose: Function
  children: React.ReactChild | React.ReactChild[]
}

function ModalForm({ open, onClose, children }: ModalProps) {
  return (
    <Modal open={open} onClose={() => onClose}>
      <div
        style={{ transform: 'translate(-50%, -50%)' }}
        className="absolute top-1/2 left-1/2 z-[1400] bg-white-main rounded-[15px]"
      >
        <Icon
          icon="fluent-mdl2:status-circle-error-x"
          className="absolute text-[30px] top-[12px] right-[20px] cursor-pointer"
          onClick={() => onClose()}
        />
        {children}
      </div>
    </Modal>
  )
}

export default ModalForm
