import React, { useState, useEffect } from 'react'
import { useMutation } from 'urql'
import _ from 'lodash'
import dayjs from 'dayjs'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'

import { ElementComponent, AlertComponent } from 'components'
import { AuthMutate } from 'services/graphql/authService'
import { general } from 'utils'
import { appSettings } from 'settings'

interface AuthFormProps {
  title?: string
  description?: string
  refno: string
  phoneNumber: string
  setRefno: Function
  onChangeForm: Function
  service: string
  cookie: string
}

type TimerType = {
  minute: number
  second: number
}

const validationSchema = yup.object({
  field1: yup.string().required(),
  field2: yup.string().required(),
  field3: yup.string().required(),
  field4: yup.string().required(),
  field5: yup.string().required(),
  field6: yup.string().required(),
})

function AuthForm({
  title,
  description = 'โปรดใส่หมายเลข OTP ยืนยันตัวตน ที่คุณได้รับ ผ่าน SMS ผ่านเบอร์',
  refno,
  phoneNumber,
  service,
  cookie,
  setRefno,
  onChangeForm,
}: AuthFormProps) {
  const [, executeMutation] = useMutation(service)
  const [, executeMutationResendOTP] = useMutation(AuthMutate.resendOTP)

  const [errorModalData, setErrorModalData] = useState({
    title: 'ยืนยัน OTP ไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const initialValues = {
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
  }

  const [timer, setTimer] = useState<TimerType>(
    JSON.parse(
      general.localStorage.getData('time') || '{ "minute": 3, "second": 0 }',
    ),
  )
  const [timeStop, setTimeStop] = useState<boolean>(
    general.localStorage.getData('time') !== '',
  )

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'ยืนยัน OTP ไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  useEffect(() => {
    const countDown = setInterval(() => {
      if (!timeStop) {
        if (
          general.localStorage.getData('time') === '' ||
          (timer.second === 0 && timer.minute === 0)
        ) {
          setTimeStop(true)
          general.localStorage.removeLocal('time')
          setTimer({ minute: 3, second: 0 })
        } else if (timer.second === 0 && timer.minute >= 1) {
          setTimer({ minute: timer.minute - 1, second: 59 })
          general.localStorage.setData(
            'time',
            JSON.stringify({ minute: timer.minute - 1, second: 59 }),
          )
        } else if (timer.second > 0) {
          setTimer({ ...timer, second: timer.second - 1 })
          general.localStorage.setData(
            'time',
            JSON.stringify({ ...timer, second: timer.second - 1 }),
          )
        }
      }
    }, 1000)

    return () => clearInterval(countDown)
  })

  const verifyAccount = async (values) => {
    await executeMutation({
      data: {
        refno: refno,
        pin: Object.values(values).join(''),
        phoneNumber: phoneNumber,
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error
        const code: number = error.graphQLErrors[0].originalError.code

        if (code) {
          setErrorModalData({
            ...handleErrorMessage(error?.graphQLErrors[0]?.message),
            open: true,
          })
          return
        }
      }

      general.cookie.setCookie(
        appSettings.AUTH_KEY,
        JSON.stringify(res.data),
        dayjs().add(1, 'm').toDate(),
      )
      general.cookie.removeCookie(cookie)

      onChangeForm()
    })
  }

  const resendOTP = async () => {
    await executeMutationResendOTP({
      data: {
        phoneNumber: phoneNumber,
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error
        const code: number = error.graphQLErrors[0].originalError.code

        if (code) {
          setErrorModalData({
            ...handleErrorMessage(error?.graphQLErrors[0]?.message),
            open: true,
          })
          return
        }
      }
      setTimeStop(false)
      setTimer({ minute: 3, second: 0 })

      const { resendOtp } = res.data
      setRefno({ refno: resendOtp.refno, phoneNumber: resendOtp.phoneNumber })

      general.localStorage.setData(
        'time',
        JSON.stringify({ minute: 3, second: 0 }),
      )
    })
  }

  const fillValue = (text: string, index: number, formData) => {
    const regex = /^[0-9\b]+$/
    if (text === '' || regex.test(text)) {
      formData.setFieldValue(`field${index + 1}`, text)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    const [, fieldIndex] = name.split('-')

    let fieldIntIndex = parseInt(fieldIndex, 10)

    if (value.length >= 1) {
      if (fieldIntIndex < 6) {
        const nextField = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`,
        ) as HTMLElement | null

        if (nextField !== null) nextField.focus()
      }
    }
  }

  const handleErrorMessage = (
    text: string,
  ): { title: string; description: string } => {
    let result = {
      title: 'ยืนยัน OTP ไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
    }

    if (text.includes('otp not found') || text.includes('Token is expire')) {
      return {
        title: 'รหัส OTP ของคุณหมดอายุ',
        description: 'กรุณาขอรหัส OTP ใหม่อีกครั้ง',
      }
    } else if (text.includes('Code is invalid')) {
      return {
        title: 'รหัส OTP ไม่ถูกต้อง',
        description: 'กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง',
      }
    }

    return result
  }

  return (
    <div className="w-full laptop:w-[470px] text-left">
      <div className="flex flex-col mb-[20px] gap-[30px] text-center laptop:text-start">
        <p className="text-[24px] laptop:text-[32px] font-bold">
          {title ?? 'ยืนยันตัวตน'}
        </p>
        <p className="text-[18px] laptop:text-[24px]">
          {`${description} ${phoneNumber}`}
        </p>
        <p
          id="otp-ref-id-message"
          className="text-gray-400 text-[18px]"
        >{`(รหัสอ้างอิง : ${refno})`}</p>
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-dark mt-[25px] mb-[42px]" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={verifyAccount}
      >
        {(formik) => (
          <Form>
            <ConnectedFocusError />
            <div className="flex justify-center laptop:justify-start gap-[20px]">
              {Object.values(formik.values).map(
                (value: string, index: number) => (
                  <Field
                    key={`input-otp-${index}`}
                    id={`field-${index + 1}`}
                    name={`field-${index + 1}`}
                  >
                    {({ field }) => (
                      <ElementComponent.TextFieldStyle
                        {...field}
                        value={value}
                        width="50px"
                        height="60px"
                        error={
                          formik.touched[`field${index + 1}`] &&
                          Boolean(formik.errors[`field${index + 1}`])
                        }
                        sx={{ fontSize: '18px' }}
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: 'center', fontSize: '18px' },
                          type: 'number',
                          pattern: '[0-9]*',
                          min: 0,
                          inputMode: 'numeric',
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          fillValue(event.target.value, index, formik)
                          handleChange(event)
                        }}
                      />
                    )}
                  </Field>
                ),
              )}
            </div>

            {!_.isEmpty(formik.errors) &&
              Object.keys(formik.values).reduce(
                (acc, cur) => formik.touched[cur] && acc,
              ) && (
                <p id="otp-error-message" className="text-red-main mt-[15px]">
                  {'กรุณากรอกหมายเลข OTP ยืนยันตัวตน'}
                </p>
              )}

            <div className="text-gray-400 text-[16px] flex justify-center laptop:justify-start gap-[4px] mt-[30px]">
              <span>ไม่ได้รับรหัส?</span>
              <span
                id="resend-otp-button"
                className={` ${
                  timeStop
                    ? 'text-blue-main cursor-pointer'
                    : 'underline cursor-not-allowed text-blueGray-500'
                } `}
                onClick={() => timeStop && resendOTP()}
              >
                ส่งรหัสผ่าน SMS อีกครั้ง
              </span>

              {!timeStop && (
                <span
                  id="otp-timer-show"
                  className="text-blue-main hover:text-blue-dark"
                >
                  {`(0${timer.minute} : ${
                    timer.second < 10 ? '0' + timer.second : timer.second
                  })`}
                </span>
              )}
            </div>

            <ElementComponent.Button
              id="otp-submit-button"
              submit
              height="52px"
              text="ยืนยัน"
              width="100%"
              style="py-[15px] mt-[60px]"
            />
          </Form>
        )}
      </Formik>

      <AlertComponent.ErrorModal
        open={errorModalData.open}
        onClose={onCloseErrorModal}
        title={errorModalData.title}
        description={errorModalData.description}
      />
    </div>
  )
}

export default AuthForm
