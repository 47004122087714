import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { useQuery } from 'urql'
import { useRecoilState } from 'recoil'
import { Icon } from '@iconify/react'

import { OverviewQuery } from 'services/graphql/overview'
import { ChartCompoent, ElementComponent } from 'components'
import { useUser } from 'stores/recoil'
import { data, date } from 'utils'
import { useResponsive } from 'hooks'
import * as SVG from 'assets/svg'

function HealthCard({
  fetching,
  error,
  cardData,
}: {
  fetching: boolean
  error: any
  cardData: any
}) {
  const { isMobile } = useResponsive()
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const [overallHealthScorce] = useQuery(
    OverviewQuery.getHealthStatusOverallDiseaseScorces(),
  )

  const formatUnderlyingDisease = (
    data: {
      underlyingDisease: {
        thaiName: string
        engName: string
      }
    }[],
  ) => {
    let formatString = ''
    data.forEach((item, index) => {
      if (index === data.length - 1)
        formatString += `${item.underlyingDisease.thaiName}`
      else formatString += `${item.underlyingDisease.thaiName},`
    })
    return formatString
  }

  return (
    <div
      className="relative bg-white-main rounded-[40px] w-full max-w-[676px]"
      style={{ filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="relative py-[40px] px-[30px] z-[10]">
        <div className="flex justify-between items-center">
          <p className={`text-[${isMobile ? '25px' : '28px'}] text-blue-main`}>
            {`HN : ${data.formatId(user['hnId'].toString())}`}
          </p>
          <svg className="h-10 w-32" viewBox="5 -5 125 50">
            <SVG.Logo4SVG />
          </svg>
        </div>
        <hr className="my-[24px]" />

        <Grid
          container
          justifyContent="space-between"
          rowGap="20px"
          columns={{ mobile: 1, laptop: 2 }}
        >
          <Grid item mobile={1}>
            {!fetching && !error ? (
              <div className="flex flex-col text-[18px] text-blue-main">
                <Grid container>
                  <Grid item mobile={5}>
                    <p>เพศ :</p>
                  </Grid>
                  <Grid item>
                    <p>{cardData.UserMedicalDemograhicData.gender}</p>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item mobile={5}>
                    <p>อายุ :</p>
                  </Grid>
                  <Grid item>
                    <p>
                      {date.getAge(
                        cardData.UserMedicalDemograhicData.birthdate,
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item mobile={5}>
                    <p>วันเกิด :</p>
                  </Grid>
                  <Grid item>
                    <p>
                      {date.convertToShowDateThai(
                        cardData.UserMedicalDemograhicData.birthdate,
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item mobile={5}>
                    <p>จังหวัด :</p>
                  </Grid>
                  <Grid item>
                    <p>{cardData.UserMedicalDemograhicData.province}</p>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item mobile={5}>
                    <p>โรคประจำตัว :</p>
                  </Grid>
                  <Grid item>
                    <p>
                      {formatUnderlyingDisease(
                        cardData.UserMedicalDemograhicData
                          .userUnderlyingDiseaseTrasaction,
                      ) !== ''
                        ? formatUnderlyingDisease(
                            cardData.UserMedicalDemograhicData
                              .userUnderlyingDiseaseTrasaction,
                          )
                        : '-'}
                    </p>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }}>
                  <Grid item mobile={5}>
                    <p>แพ้ยา/อาหาร :</p>
                  </Grid>
                  <Grid item>
                    <p>
                      {cardData.UserMedicalDemograhicData.allergic !== ''
                        ? cardData.UserMedicalDemograhicData.allergic
                        : '-'}
                    </p>
                  </Grid>
                </Grid>
              </div>
            ) : (
              'loading'
            )}
          </Grid>

          <Grid item laptop="auto" mobile={1}>
            <div>
              {!overallHealthScorce.fetching && !overallHealthScorce.error ? (
                <div
                  className={`border-[3px] border-blue-main ${
                    isMobile ? 'w-full' : 'w-fit'
                  } p-[20px] rounded-[20px] text-center`}
                >
                  <ChartCompoent.ProgresChartHealthScorce
                    title={'สุขภาพโดยรวมของคุณ'}
                    data={
                      overallHealthScorce.data
                        .getHealthStatusOverallDiseaseScorces
                    }
                  />
                </div>
              ) : (
                'loading'
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <SVG.CurveCardSVG className="absolute bottom-0 left-0 z-0 rounded-b-[40px]" />
    </div>
  )
}

function OverviewPage() {
  const { isMobile } = useResponsive()
  const [medicalQueryData] = useQuery(OverviewQuery.getUserOverviewData())
  const [getDiseaseData] = useQuery(OverviewQuery.getDiseaseData())
  const [seletDisease, setSeletDisease] = useState<any>({})
  const [healthScorce] = useQuery(
    OverviewQuery.getHealthStatusDiseaseScorces(seletDisease.dId),
  )
  const [diseaseAttibuteCount] = useQuery(
    OverviewQuery.getDiseasesAttibutes(seletDisease.dId),
  )

  useEffect(() => {
    if (!getDiseaseData.fetching) {
      setSeletDisease(getDiseaseData.data.getDiseases[0])
    }

    return () => {}
  }, [getDiseaseData.fetching])

  const handleWidth = (length: number, index: number) => {
    if (length % 2 === 0 && length === index) return 1.84
    return 0.9
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-[20px] mb-[18px]">
        <Icon icon="fluent-mdl2:text-document-shared" className="text-[24px]" />
        <p className="text-[20px] font-bold">ภาพรวม</p>
      </div>

      <HealthCard
        fetching={medicalQueryData.fetching}
        error={medicalQueryData.error}
        cardData={medicalQueryData.data?.getUser}
      />

      <div
        className={`bg-white-main rounded-[10px] ${
          isMobile ? 'px-[18px] py-[40px]' : 'p-[35px]'
        } mt-[40px]`}
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Formik
          initialValues={{ disease: '' }}
          onSubmit={(values) => setSeletDisease(values.disease)}
        >
          {(formik) => (
            <form onSubmit={(e) => e.defaultPrevented}>
              {!getDiseaseData.fetching && !getDiseaseData.error ? (
                <div>
                  <ElementComponent.SelectBar.SelectBar
                    name="disease"
                    label="เลือกโรคที่จะสรุป"
                    placeholder="เลือกโรคที่จะสรุป"
                    width={isMobile ? 'w-full' : 'w-main'}
                    data={seletDisease.dId}
                    option={getDiseaseData?.data?.getDiseases?.map((data) => ({
                      key: data.dId,
                      value: data.thaiName,
                    }))}
                    onChange={(value: string) => {
                      formik.setFieldValue('disease', value)
                      setSeletDisease(
                        getDiseaseData?.data?.getDiseases.filter(
                          (item) => item.dId === value,
                        )?.[0],
                      )
                    }}
                  />
                </div>
              ) : (
                'loading'
              )}
            </form>
          )}
        </Formik>

        <Grid
          container
          justifyContent="start"
          columnGap="28px"
          rowGap="20px"
          columns={{ mobile: 1, laptop: 2 }}
          sx={{ marginTop: '30px' }}
        >
          <Grid item mobile={1} laptop={0.9}>
            {!healthScorce.fetching && !healthScorce.error ? (
              <div
                className="relative min-h-[350px] h-full p-[20px] bg-white-main rounded-[20px] flex justify-center items-center"
                style={{
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div className="relative z-10 px-[20px] py-[40px] text-center border-[3px] border-blue-main rounded-[20px]">
                  <ChartCompoent.ProgresChartHealthScorce
                    data={healthScorce.data.getHealthStatusDiseaseScorces}
                    title={`สุขภาพโดยรวมของ${seletDisease.thaiName}`}
                  />
                </div>
                <SVG.CurveCardSVG className="absolute bottom-0 left-0 z-0 rounded-b-[20px]" />
              </div>
            ) : (
              'loading'
            )}
          </Grid>

          {!diseaseAttibuteCount.fetching && !diseaseAttibuteCount.error ? (
            <>
              {diseaseAttibuteCount.data.getDiseasesAttibutes.map(
                (data, index) => (
                  <Grid
                    item
                    key={`graph_${index}`}
                    mobile={1}
                    laptop={handleWidth(
                      diseaseAttibuteCount.data.getDiseasesAttibutes.length,
                      index + 1,
                    )}
                    sx={{
                      height: '100%',
                      minHeight: '300px',
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                      borderRadius: '18px',
                      padding: '20px',
                    }}
                  >
                    <ChartCompoent.LineChartMedicalData
                      daId={data.daId}
                      thaiName={data.thaiName}
                      description={data.description}
                      unitThai={data.unitThai}
                    />
                  </Grid>
                ),
              )}
            </>
          ) : (
            'Loading'
          )}
        </Grid>
      </div>
    </div>
  )
}

export default OverviewPage
