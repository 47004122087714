import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack, CircularProgress } from '@mui/material'
import { useRecoilState } from 'recoil'

import { ElementComponent } from 'components'
import { UploadSuccessSVG } from 'assets/svg'
import { useUser } from 'stores/recoil'
import { useResponsive } from 'hooks'
import { route, urls } from 'settings'
import { data } from 'utils'

function UploadSuccessPage() {
  const { isMobile } = useResponsive()
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const navigate = useNavigate()

  const handleHeightContent = () => {
    if (contentRef) {
      const height = window.innerHeight - contentRef.current?.clientHeight

      if (height < 60) return 'mt-[90px]'
    }
  }

  return user['hnId'] ? (
    <div className="laptop:bg-white-darker bg-white-main h-full min-h-screen relative -mt-[90px]">
      <div
        ref={contentRef}
        className={`py-[40px] px-[18px] flex flex-col items-center rounded-[15px] laptop:text-[18px] text-[16px] bg-white-main w-full laptop:max-w-[900px] absolute top-1/2 left-1/2 ${handleHeightContent()}`}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <p className="text-[32px] text-blue-main">
          ข้อมูลของท่านถูกส่งไปยังคุณหมอแล้ว
        </p>
        <UploadSuccessSVG className="mt-[40px] mb-[30px] w-full" />
        <p className="text-red-main mt-[7px]">
          หมายเหตุ * กรุณาจดบันทึกภาพหน้านี้ หรือจดเลข HNID เพื่อส่งให้คุณหมอ
        </p>

        <div className="flex justify-center mt-[20px] w-full">
          <div className="w-full max-w-[398px] py-[15px] px-[20px] text-center bg-blue-light border border-blue-main">
            <p className="text-[20px]">
              HN : {data.formatId(user['hnId'].toString())}
            </p>
          </div>
        </div>

        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          className="mt-[40px] gap-[20px] justify-center w-full max-w-[500px] p-[18px]"
        >
          <div className="order-2 laptop:order-1">
            <ElementComponent.Button
              id="back-button"
              height="52px"
              width={isMobile ? '100%' : '240px'}
              text="กลับสู่หน้าข้อมูลสุขภาพ"
              shape="outlined"
              onAction={() => navigate(route.HEALTH_PAGE_URL)}
            />
          </div>
          <div className="order-1 laptop:order-2">
            <a href={urls.LINE_OFFCIAL_GRUOP} target="_blank">
              <ElementComponent.Button
                id="meet-doctor-button"
                height="52px"
                width={isMobile ? '100%' : '240px'}
                text="เข้าพบหมอ"
              />
            </a>
          </div>
        </Stack>
      </div>
    </div>
  ) : (
    <div className="laptop:bg-white-darker bg-white-main h-full min-h-screen relative mt-[-90px]">
      <div
        className="py-[40px] flex flex-col items-center rounded-[15px] text-[18px] bg-white-main w-full laptop:max-w-[600px] absolute top-1/2 left-1/2"
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <CircularProgress
          sx={{ marginTop: '10px', marginBottom: '50px' }}
          size={50}
        />
        <p>กำลังทำการอัพโหลดข้อมูล</p>
      </div>
    </div>
  )
}

export default UploadSuccessPage
