import React, { useState, useEffect } from 'react'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'

import { RegisterSVG } from 'assets/svg'
import { FormComponent } from 'components'
import { RegisterMutate } from 'services/graphql/registerService'
import { RegisterType } from 'types'
import { general } from 'utils'
import { appSettings } from 'settings'
import { useResponsive } from 'hooks'
import { SuccessProgressPages } from 'pages'

function Register() {
  const { isLaptop } = useResponsive()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [form, setForm] = useState<RegisterType.RegiseterStatus>(
    RegisterType.RegiseterStatus.REGISTER,
  )
  const [data, setData] = useState<{ refno: string; phoneNumber: string }>({
    refno: '',
    phoneNumber: '',
  })

  useEffect(() => {
    if (general.cookie.getCookie(appSettings.REGISTER_KEY)) {
      const { register } = JSON.parse(
        general.cookie.getCookie(appSettings.REGISTER_KEY),
      )
      setData({ refno: register.refno, phoneNumber: register.phoneNumber })

      if (form === 'REGISTER') setForm(RegisterType.RegiseterStatus.AUTH)
    }
  }, [form])

  const onChangeFormSuccess = () => {
    setForm(RegisterType.RegiseterStatus.SUCCESS)
    navigate({
      search: createSearchParams({
        register: 'success',
      }).toString(),
    })
  }

  const Render = () => {
    if (
      form === RegisterType.RegiseterStatus.SUCCESS ||
      searchParams.get('register') === 'success'
    ) {
      return <SuccessProgressPages.RegisterSuccessPage />
    }

    return (
      <div className="flex h-full">
        {isLaptop && (
          <div
            style={{ backgroundColor: 'rgba(20, 117, 187, 1)' }}
            className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
          >
            <RegisterSVG />
            <p className="text-[28px] mt-[60px] p-[20px]">
              DR. ASA PRIMARY CARE PLATFORM
            </p>
            <p className="mx-[130px]">
              “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
              มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย Dr. ASA”
            </p>
          </div>
        )}

        <div className="w-full laptop:w-1/2 text-center px-[18px] py-[50px] laptop:p-[57px] bg-white-dark overflow-auto">
          {form === RegisterType.RegiseterStatus.REGISTER && (
            <FormComponent.RegisterForm.RegisterForm onChangeForm={setForm} />
          )}

          {form === RegisterType.RegiseterStatus.AUTH && (
            <FormComponent.AuthForm
              cookie={appSettings.REGISTER_KEY}
              refno={data.refno}
              setRefno={setData}
              phoneNumber={data.phoneNumber}
              service={RegisterMutate.verifyRegister}
              onChangeForm={onChangeFormSuccess}
            />
          )}
        </div>
      </div>
    )
  }

  return Render()
}

export default Register
