import React from 'react'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'

export default function PasswordForm({ form }: RegisterType.FormProps) {
  return (
    <div>
      <p className="text-[18px] font-bold mb-[25px]">ข้อมูลเพิ่มเติม</p>
      <div className="flex flex-col gap-[13px]">
        <ElementComponent.PasswordBar
          label="รหัสผ่าน"
          name="password"
          height="50px"
          placeholder="รหัสผ่าน"
          value={form.values.password}
          errorText={form.errors.password}
          error={form.touched.password && Boolean(form.errors.password)}
          onChange={(text: string) => form.setFieldValue('password', text)}
        />

        <p className="mt-[10px]">รหัสผ่านต้องมีความยาวตั้งแต่ 8 ถึง 32 ตัว</p>
        <p>ประกอบด้วย</p>

        <ul className="list-disc ml-[20px] mb-[20px]">
          <li>ตัวอักษรอังกฤษ (a-z, A-Z) อย่างน้อย 1 ตัว</li>
          <li>ตัวเลข อย่างน้อย 1 ตัว</li>
        </ul>

        <ElementComponent.PasswordBar
          label="ยืนยันรหัสผ่าน"
          name="confirmPassword"
          height="50px"
          placeholder="ยืนยันรหัสผ่าน"
          value={form.values.confirmPassword}
          errorText={form.errors.confirmPassword}
          error={
            form.touched.confirmPassword && Boolean(form.errors.confirmPassword)
          }
          onChange={(text: string) =>
            form.setFieldValue('confirmPassword', text)
          }
        />
      </div>
    </div>
  )
}
