import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Field } from 'formik'
import { TextField, InputAdornment, Skeleton } from '@mui/material'

interface InputBarProps {
  frontIcon?: React.ReactNode
  backIcon?: React.ReactNode
  name?: string
  value: string
  width?: string
  height?: string
  label?: string
  errorText?: string
  error?: boolean
  placeholder?: string
  disabled?: boolean
  focusSpacing?: boolean
  style?: string
  onChange: Function
  isLoading: boolean
}

interface TextFieldStyleProps {
  height: string
  width: string
  error: boolean
}

export const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'error',
})<TextFieldStyleProps>(({ height, width, error }) => ({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiOutlinedInput-root': {
    height: height,
    width: width,
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    fontFamily: 'Roboto, Sarabun',
    frontSize: '16px',
    '& fieldset': {
      borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(231, 231, 231, 1)',
    },
    '&:hover fieldset': {
      borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(201, 226, 244, 1)',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(20, 117, 187, 1)',
    },
  },
  '& input::placeholder': {
    fontSize: '16px',
  },
}))

function InputBar({
  frontIcon,
  backIcon,
  value,
  name,
  width = '100%',
  height = '35px',
  errorText,
  error,
  label,
  placeholder,
  focusSpacing = true,
  style,
  disabled,
  onChange,
  isLoading,
}: InputBarProps) {
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  return !isLoading ? (
    <div
      className={`${style ?? ''} ${
        focusSpacing && (cliked || value || disabled)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }`}
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <>
            <div className="relative w-fit">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  cliked || value || disabled
                    ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                    : 'absolute top-0.25 p-2 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>
            <TextFieldStyle
              {...field}
              onClick={(e) => onHandleClick(e, field.onClick)}
              onBlur={(e) => onHandleBlur(e, field.onBlur)}
              value={value || ''}
              disabled={disabled}
              error={error}
              fullWidth
              height={height}
              width={width}
              placeholder={cliked ? '' : placeholder}
              aria-label={placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ paddingRight: frontIcon ? '5px' : '0px' }}
                  >
                    {frontIcon}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ paddingLeft: backIcon ? '5px' : '0px' }}
                  >
                    {backIcon}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Field>
      {error && (
        <p
          id={`error-${name}`}
          className="text-[14px] text-red-main mt-[6px] text-start"
        >
          {errorText}
        </p>
      )}
    </div>
  ) : (
    <div className={`${style}`}>
      <Skeleton variant="rounded" width={width} height={height} />
    </div>
  )
}

export default InputBar
