import React, { useEffect, useState } from 'react'
import { useMutation } from 'urql'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'

import { ElementComponent, AlertComponent } from 'components'
import { AuthMutate } from 'services/graphql/authService'
import { LoginType } from 'types'
import { general } from 'utils'
import { appSettings } from 'settings'

interface ResetFormProps {
  onChangeForm: Function
}

const initialValues: LoginType.ResetDataType = {
  password: '',
  confirmPassword: '',
}

const validationSchema = yup.object({
  password: yup
    .string()
    .min(
      8,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .max(
      32,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .matches(
      /^(?=.*[a-zA-Z9-9])/,
      'กรุณากรอกรหัสผ่านที่มีตัวอักษรอังกฤษ  และ ตัวเลขอย่างละ 1 ตัว กับ มีความยาวตั้งแต่ 8 ถึง 32 ตัว',
    )
    .required('กรุณาตั้งรหัสผ่านใหม่'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      'กรุณากรอกยืนยันรหัสผ่านให้ตรงกับรหัสผ่านใหม่',
    )
    .required('กรุณากรอกยืนยันรหัสผ่าน'),
})

function ResetForm({ onChangeForm }: ResetFormProps) {
  const [, executeMutation] = useMutation(AuthMutate.setNewPassword)
  const [cookie, setCookie] = useState({ token: '', phoneNumber: '' })
  const [errorModalData, setErrorModalData] = useState({
    title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const resetPassword = async (values) => {
    await executeMutation({
      data: {
        token: cookie.token,
        phoneNumber: cookie.phoneNumber,
        password: values.password,
      },
    }).then(async (res) => {
      if (res.error) {
        setErrorModalData({
          title: 'เปลี่ยนรหัสผ่านไม่สำเร็จ',
          description: 'กรุณาทดลองใหม่อีกครั้ง',
          open: true,
        })
        return
      }

      general.cookie.setCookie(
        appSettings.USER_KEY,
        JSON.stringify(res.data),
        dayjs().add(1, 'm').toDate(),
      )
      general.cookie.removeCookie(appSettings.AUTH_KEY)
      await onChangeForm()
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { verifyForgetPassword } = await JSON.parse(
          general.cookie.getCookie(appSettings.AUTH_KEY),
        )
        setCookie({
          token: verifyForgetPassword?.token,
          phoneNumber: verifyForgetPassword?.phoneNumber,
        })
      } catch {
        general.http.redirect()
      }
    })()
  }, [])

  return (
    <div className="text-start">
      <p
        id="message-reset-password"
        className="laptop:text-[32px] text-[24px] mb-[30px] laptop:text-start text-center font-bold"
      >
        ตั้งรหัสผ่านใหม่
      </p>
      <p className="laptop-text-[24px] text-[18px] mb-[24px]">
        กรุณาตั้งรหัสผ่านใหม่ โดยมีเงื่อนไขในการตั้งรหัสผ่าน ดังนี้
      </p>

      <div className="flex flex-col gap-[13px] w-full laptop:w-[470px] text-start">
        <div className="ml-[20px] laptop:ml-0 flex flex-col gap-[13px]">
          <p>รหัสผ่านต้องมีความยาวตั้งแต่ 8 ถึง 32 ตัว</p>
          <p>ประกอบด้วย</p>

          <ul className="list-disc ml-[20px]">
            <li>ตัวอักษรอังกฤษ (a-z, A-Z) อย่างน้อย 1 ตัว</li>
            <li>ตัวเลข อย่างน้อย 1 ตัว</li>
          </ul>
        </div>

        <hr className="border-t-[1px] border-dashed border-gray-dark my-[20px]" />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={resetPassword}
        >
          {(formik) => (
            <Form>
              <ConnectedFocusError />
              <div className="flex flex-col mb-[40px] gap-main">
                <ElementComponent.PasswordBar
                  label="กรอกรหัสผ่านใหม่"
                  name="password"
                  placeholder="กรอกรหัสผ่านใหม่"
                  height="50px"
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  errorText={formik.errors.password as string}
                  onChange={(text: string) =>
                    formik.setFieldValue('password', text)
                  }
                />

                <ElementComponent.PasswordBar
                  label="กรอกรหัสผ่านอีกครั้ง"
                  name="confirmPassword"
                  placeholder="กรอกรหัสผ่านอีกครั้ง"
                  height="50px"
                  value={formik.values.confirmPassword}
                  errorText={formik.errors.confirmPassword as string}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  onChange={(text: string) =>
                    formik.setFieldValue('confirmPassword', text)
                  }
                />
              </div>

              <ElementComponent.Button
                id="reset-password-submit-button"
                submit
                text="ยืนยัน"
                width="100%"
                height="52px"
              />
            </Form>
          )}
        </Formik>

        <AlertComponent.ErrorModal
          open={errorModalData.open}
          onClose={onCloseErrorModal}
          title={errorModalData.title}
          description={errorModalData.description}
        />
      </div>
    </div>
  )
}

export default ResetForm
