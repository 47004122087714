import React from 'react'
import { Stack } from '@mui/material'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { route } from 'settings'
import { MenuComponent } from 'components'

const menuListOption = {
  title: 'ข้อมูลบัตรคนรักสุขภาพ',
  menu: [
    {
      text: 'ภาพรวม',
      icon: 'fluent-mdl2:text-document-shared',
      path: route.OVERVIEW_PAGE_URL,
    },
    {
      text: 'ข้อมูลสุขภาพ',
      icon: 'ri:shield-cross-line',
      path: route.HEALTH_PAGE_URL,
    },
  ],
}

function UserLayout() {
  const { isLaptop } = useResponsive()
  const navigate = useNavigate()
  const location = useLocation()

  const fullScreen = (): boolean => {
    const uploadSuccess = Boolean(
      location.pathname === route.UPLOAD_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )
    const createSuccess = Boolean(
      location.pathname === route.CREATE_HEALTH_PAGE_URL &&
        location.search === '?upload=success',
    )

    return uploadSuccess || createSuccess
  }

  if (fullScreen()) {
    return (
      <div>
        <Outlet />
      </div>
    )
  }

  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      className="bg-white-dark h-full min-h-[80vh] laptop:min-h-[77vh] flex mt-[-90px] laptop:mt-0 laptop:pl-[50px] pt-[60px] pb-[90px]"
    >
      {isLaptop && (
        <MenuComponent.UserOptionBar
          title={menuListOption.title}
          menu={menuListOption.menu}
          options={location.pathname}
          onChangeOptions={(path: string) => {
            navigate(path)
            window.scrollTo(0, 0)
          }}
        />
      )}

      <div className="w-full px-[20px] pt-0 laptop:pl-0 laptop:pr-[50px] laptop:ml-[345px]">
        <Outlet />
      </div>
    </Stack>
  )
}

export default UserLayout
