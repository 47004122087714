import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Icon } from '@iconify/react'

import { route } from 'settings'
import { MenuComponent, FormComponent } from 'components'
import { SuccessProgressPages } from 'pages'

const menuList = [
  { title: 'ข้อมูลสุขภาพ', link: route.HEALTH_PAGE_URL },
  { title: 'สร้างข้อมูลสุขภาพ', link: '' },
]

function CreateHealthSection() {
  const [searchParams] = useSearchParams()

  if (searchParams.get('upload') === 'success') {
    return <SuccessProgressPages.UploadSuccessPage />
  }

  return (
    <div>
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex items-center gap-[13px] mt-[25px]">
        <Icon icon="mdi:heart-plus" className="text-[28px]" />
        <p className="text-[20px] font-bold">สร้างข้อมูลสุขภาพ</p>
      </div>

      <FormComponent.HealthForm.HealthForm />
    </div>
  )
}

export default CreateHealthSection
