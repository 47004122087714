import { route } from 'settings/index'

/**
 * `refresh()` is a function that reloads the page
 */
const refresh = () => window.location.reload()

/**
 * It takes a string and changes the URL to that string
 * @param {string} page - The page to go to.
 */
const goto = (page: string) => window.location.assign(page)

const newTabTo = (page: string) =>
  window.open(page, '_blank') || window.location.replace(page)

/**
 * Redirect to Dashboard Page
 */
const redirect = () => goto(route.LANDING_PAGE_URL)

export { refresh, newTabTo, redirect, goto }
