import React, { useState } from 'react'
import { useMutation } from 'urql'
import { Icon } from '@iconify/react'
import dayjs from 'dayjs'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'
import { useRecoilState } from 'recoil'

import { ElementComponent, AlertComponent } from 'components'
import { AuthMutate } from 'services/graphql/authService'
import { useSystem } from 'stores/recoil'
import { useResponsive } from 'hooks'
import { appSettings } from 'settings'
import { general } from 'utils'

interface ForgetFormProps {
  onChangeForm: Function
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณากรอกเบอร์โทรศัพท์'),
})

function ForgetForm({ onChangeForm }: ForgetFormProps) {
  const { isMobile } = useResponsive()
  const [, setOpenLoginBar] = useRecoilState(
    useSystem.selector.selectorSwipeableDrawerLoginStateAtom,
  )
  const [forgetMutation, executeMutation] = useMutation(
    AuthMutate.forgetPassword,
  )
  const [errorModalData, setErrorModalData] = useState({
    title: 'รีเซ็ตรหัสผ่านไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const initialValues = {
    phone: '',
  }

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'รีเซ็ตรหัสผ่านไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const onClickLogin = () => {
    if (isMobile) {
      setOpenLoginBar(true)
      return
    }
    general.http.goto('/login')
  }

  const requestForget = async (values) => {
    await executeMutation({
      data: {
        phoneNumber: values.phone,
      },
    }).then(async (res) => {
      if (res.error) {
        const error: any = res.error
        const code: number = error.graphQLErrors[0].originalError.code

        if (code === 400) {
          setErrorModalData({
            title: 'ไม่สามารถขอเปลี่ยนรหัสผ่านได้ ',
            description: 'เนื่องจากยังไม่ได้ทำการลงทะเบียน',
            open: true,
          })
          return
        }

        setErrorModalData({
          title: 'รีเซ็ตรหัสผ่านไม่สำเร็จ',
          description: 'กรุณาทดลองใหม่อีกครั้ง',
          open: true,
        })
        return
      }
      general.cookie.setCookie(
        appSettings.FORGET_KEY,
        JSON.stringify(res.data),
        dayjs().add(1, 'h').toDate(),
      )
      await onChangeForm()
    })
  }

  return (
    <div className="laptop:w-[470px] w-full laptop:text-left text-center">
      <div className="flex flex-col laptop:gap-[30px] gap-[20px]">
        <p className="laptop:text-[32px] text-[24px] font-bold">ลืมรหัสผ่าน</p>
        <p className="laptop:text-[24px] text-[18px]">
          รีเซ็ตรหัสผ่านด้วยเบอร์โทรศัพท์ที่ลงทะเบียนไว้
        </p>
      </div>
      <hr className="border-t-[1px] border-dashed border-gray-dark mt-[25px] mb-[42px]" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={requestForget}
      >
        {(formik) => (
          <Form>
            <ConnectedFocusError />
            <div className="flex flex-col gap-[64px]">
              <ElementComponent.InputBar
                name="phone"
                label="เบอร์โทรศัพท์"
                placeholder="เบอร์โทรศัพท์"
                width="100%"
                height="50px"
                value={formik.values.phone}
                errorText={formik.errors.phone as string}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                onChange={(text: string) => formik.setFieldValue('phone', text)}
                frontIcon={
                  <Icon
                    icon="healthicons:phone"
                    className={`text-[20px] ${
                      formik.touched.phone && Boolean(formik.errors.phone)
                        ? 'text-red-light'
                        : 'text-trueGray-400'
                    } ml-[12px] mr-[8px]`}
                  />
                }
                isLoading={forgetMutation.fetching}
              />

              <ElementComponent.Button
                id="forget-submit-button"
                submit
                text="ขอรับเลข OTP"
                width="100%"
                height="52px"
              />
            </div>
          </Form>
        )}
      </Formik>

      <AlertComponent.ErrorModal
        open={errorModalData.open}
        onClose={onCloseErrorModal}
        title={errorModalData.title}
        description={errorModalData.description}
      />

      <div className="mt-[20px] flex justify-center">
        <div
          onClick={onClickLogin}
          className="text-[16px] w-fit flex justify-center items-center gap-[8px]"
        >
          <Icon icon="carbon:arrow-left" />
          <span>กลับไปยังหน้า</span>
          <span id="login-link" className="text-blue-main cursor-pointer">
            เข้าสู่ระบบ
          </span>
        </div>
      </div>
    </div>
  )
}

export default ForgetForm
