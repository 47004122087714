export const getUserOverviewData = () => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            hnId
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                              province
                              district
                              allergic
                              userUnderlyingDiseaseTrasaction {
                                underlyingDisease {
                                  thaiName
                                  engName
                                }
                              }
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: '',
      },
    },
  }
}

export const getDiseaseData = () => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
    getDiseases(where: $where, orderBy: $orderBy) {
      dId
      thaiName
    }
  }`

  return {
    query: getDisease,
    variables: {
      where: {
        showFlag: {
          equals: true,
        },
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDiseasesAttibutes = (disease_dId: string) => {
  const getDisease = `query getDiseasesAttibutes($where: DiseaseAttibuteWhereInput!){
    getDiseasesAttibutes(where: $where) {
      daId
      description
      thaiName
      medName
      unitThai
    }
  }`

  return {
    query: getDisease,
    variables: {
      where: {
        disease: {
          is: {
            dId: {
              equals: disease_dId,
            },
          },
        },
        showFlag: {
          equals: true,
        },
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getHealthStatusOverallDiseaseScorces = () => {
  const getHealthStatusOverallDiseaseScorces = `query getHealthStatusOverallDiseaseScorces($where: HealthStatusOverallScorceDataWhereInput! $orderBy: [HealthStatusOverallScorceDataOrderByWithRelationInput!], $take: Int){
    getHealthStatusOverallDiseaseScorces(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getHealthStatusOverallDiseaseScorces,
    variables: {
      where: {
        puId: {
          equals: '',
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getHealthStatusDiseaseScorces = (disease_dId: string) => {
  const getHealthStatusDiseaseScorces = `query getHealthStatusDiseaseScorces($where: HealthStatusDiseaseScorceDataWhereInput! $orderBy: [HealthStatusDiseaseScorceDataOrderByWithRelationInput!], $take: Int){
    getHealthStatusDiseaseScorces(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getHealthStatusDiseaseScorces,
    variables: {
      where: {
        puId: {
          equals: '',
        },
        disease: {
          is: {
            dId: {
              equals: disease_dId,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getMedDataTransactions = (daId: string) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: '',
            },
          },
        },
        daId: {
          equals: daId,
        },
        diseaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getDiseaseAttibutesCondition = (daId: string) => {
  const querySchema = `query getDiseaseAttibuteCondition($where: DiseaseAttibuteWhereInput) {
                        getDiseaseAttibuteCondition(where: $where) {
                          daId
                          condition
                        }
                      }`

  return {
    query: querySchema,
    variables: {
      where: {
        daId: {
          equals: daId,
        },
      },
    },
  }
}
