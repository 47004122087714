import React, { FC, useState } from 'react'
import { Field } from 'formik'

export type OptionSelectType = {
  key: string
  value: string
}

interface ICameraDropDownProps {
  name?: string
  data: any
  option: OptionSelectType[]
  onChange: Function
  onClick?: Function
  onBlur?: Function
  width?: string
  height?: string
  error?: boolean
  errorText?: string
  label: string
  disable?: boolean
  placeholder: string
  focusSpacing?: boolean
}

export const SelectBar: FC<ICameraDropDownProps> = (props) => {
  const {
    name,
    data,
    option,
    onChange,
    onClick,
    onBlur,
    width,
    height = 'h-input',
    error,
    errorText,
    label,
    disable = false,
    placeholder,
    focusSpacing = true,
  } = props
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)
    if (onClick) onClick()

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)
    if (onBlur) onBlur()

    if (defaultOnblur) defaultOnblur(e)
  }

  const handleOnChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <div
      className={
        focusSpacing && (cliked || data)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <>
            <div className="relative w-fit border">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  cliked || (data !== undefined && data !== null && data !== '')
                    ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                    : 'absolute top-0.25 p-2 -z-10 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>

            <select
              {...field}
              className={`rounded-md px-5 py-1 pr-[35px] text-base font-normal
                    ${width} ${height} outline outline-1 appearance-none
                    ${
                      error && !disable
                        ? 'outline-red-main'
                        : 'outline-gray-light hover:outline-[#E7E7E7] focus:outline-2 focus:outline-blue-main '
                    }
                    ${!disable ? 'cursor-pointer' : 'cursor-not-allowed '}
                    ${
                      data === '' ||
                      data === undefined ||
                      data === null ||
                      disable
                        ? 'text-[#00000050]'
                        : 'text-gray-700'
                    }
                    `}
              style={{
                background: `${
                  disable ? '#F1F1F1' : '#FFFFFF'
                } url(https://api.iconify.design/material-symbols/arrow-drop-down.svg) no-repeat calc(100% - 10px) center / 25px`,
              }}
              onChange={handleOnChange}
              onClick={(e) => onHandleClick(e, field.onClick)}
              onBlur={(e) => onHandleBlur(e, field.onBlur)}
              disabled={disable}
              value={data}
              // defaultValue={placeholder}
            >
              <option value="" className="text-[#00000050]" disabled>
                {placeholder}
              </option>
              {option.map((item: OptionSelectType, index: number) => (
                <option
                  key={`options-${index}`}
                  value={item.key}
                  className="text-left text-gray-700"
                >
                  {item.value}
                </option>
              ))}
            </select>
          </>
        )}
      </Field>
      {error && (
        <p
          id={`error-${name}`}
          className="text-[14px] text-red-main mt-[6px] text-start"
        >
          {errorText}
        </p>
      )}
    </div>
  )
}
