import React, { useState, useEffect } from 'react'
import { Pagination, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useQuery } from 'urql'
import { date } from 'utils'

import { ElementComponent } from 'components'
import { NoDiseaseDataSVG } from 'assets/svg'
import { useResponsive } from 'hooks'
import { route, appSettings } from 'settings'
import { MedicalQuery } from 'services/graphql/medicalService'

interface HealthDataCardProps {
  data: any
}

interface OptionsProps {
  disableViwer?: boolean
  disableDiagnos?: boolean
  healthId?: string
}

const headerTables = [
  { key: 'NUMBER', value: 'ลำดับ' },
  { key: 'RESULT', value: 'ผลลัพธ์การวินิจฉัย' },
  { key: 'DOCTOR', value: 'ผู้ตรวจ' },
  { key: 'DIAGNOSE_DATE', value: 'วันที่วินิจฉัย' },
  { key: 'BMI', value: 'BMI' },
  { key: 'OXYGEN', value: 'ออกซิเจนในกระแสเลือด' },
  { key: 'PRESSURE', value: 'ความดันโลหิต' },
  { key: 'UPLOAD_DATE', value: 'วันที่บันทึกข้อมูล' },
  { key: 'SUBMITER', value: 'ผู้ที่บันทึก' },
  { key: 'OPTIONS', value: 'ตัวเลือก' },
]

function Options({
  disableViwer = false,
  disableDiagnos = false,
  healthId = '',
}: OptionsProps) {
  const navigate = useNavigate()

  const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#C9E2F4',
      color: '#1475BB',
      fontSize: 12,
    },
  })

  return (
    <div className="flex justify-center items-center gap-[5px]">
      <StyleToolTip title="ดูรายละเอียดข้อมูล">
        <Icon
          icon="mdi:drive-document"
          className={`text-[30px] ${
            disableViwer
              ? 'cursor-not-allowed text-gray-main'
              : 'cursor-pointer text-blue-main'
          }`}
          onClick={() =>
            !disableViwer && navigate(`${route.HEALTH_PAGE_URL}/${healthId}`)
          }
        />
      </StyleToolTip>
      <StyleToolTip title="ดูผลวินิจฉัย">
        <Icon
          icon="fluent:document-search-20-filled"
          className={`text-[30px] ${
            disableDiagnos
              ? 'cursor-not-allowed text-gray-main'
              : 'cursor-pointer text-blue-main'
          }`}
          onClick={() =>
            !disableDiagnos &&
            navigate(`${route.HEALTH_PAGE_URL}/${healthId}/diagnosis`)
          }
        />
      </StyleToolTip>
    </div>
  )
}

function HealthDataCard({ data }: HealthDataCardProps) {
  return (
    <div
      className="bg-white-main rounded-[10px] p-[15px]"
      style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
    >
      <div>
        <div className="flex flex-row justify-between gap-[10px]">
          {['NUMBER', 'RESULT', 'OPTIONS'].map(
            (topic: string, index: number) => (
              <div
                key={`table-data-${index}`}
                className={`flex flex-col ${
                  topic !== 'OPTIONS' ? 'items-start' : 'items-end'
                } ${
                  topic !== 'RESULT' ? 'w-fit' : 'max-w-[780px] w-full'
                } gap-[8px]`}
              >
                <div className="bg-gray-light rounded-[6px] whitespace-nowrap px-[10px] py-[3px] w-fit">
                  {headerTables.filter((item) => item.key === topic)[0].value}
                </div>

                {topic !== 'OPTIONS' ? (
                  <p
                    className={`${
                      topic !== 'RESULT' ? 'text-center' : 'text-start'
                    } break-words whitespace-pre-wrap w-full`}
                  >
                    {data.filter((item) => item.key === topic)[0].value}
                  </p>
                ) : (
                  data.filter((item) => item.key === topic)[0].value
                )}
              </div>
            ),
          )}
        </div>
      </div>

      <hr className="my-[25px]" />

      <div>
        <div className="flex gap-[10px]">
          {['DOCTOR', 'DIAGNOSE_DATE'].map((topic: string, index: number) => (
            <div
              key={`option-${topic}-${index}`}
              className="flex flex-col gap-[8px]"
            >
              <div className="bg-gray-light rounded-[6px] px-[10px] py-[3px] w-fit">
                {headerTables.filter((item) => item.key === topic)[0].value}
              </div>
              <div className="text-center">
                {data.filter((item) => item.key === topic)[0].value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function HealthPage() {
  const navigate = useNavigate()
  const { isLaptop, width } = useResponsive()

  const [tableData, setTableData] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [page, setPage] = useState<number>(1)

  const [submitCount] = useQuery(MedicalQuery.getSubmitCount())
  const [medicalData, executeQueryData] = useQuery(
    MedicalQuery.getMedicalData(
      isLaptop ? 10 : 5,
      (page - 1) * (isLaptop ? 10 : 5),
    ),
  )

  const formatData = (data: any, number: number) => {
    let medTrasaction = Array(4).fill('-')

    data.userMedTrasaction?.forEach((med: any) => {
      const medName = med?.diseaseAttibute?.medName

      switch (medName) {
        case 'BMI':
          medTrasaction[0] = med?.value
          break
        case 'Oxygen saturation':
          medTrasaction[1] = med?.value
          break
        case 'Diastolic blood pressure (DBP)':
          medTrasaction[2] = med?.value
          break
        case 'Systolic blood pressure (SBP)':
          medTrasaction[3] = med?.value
          break
        default:
      }
    })

    return [
      { key: 'NUMBER', value: number },
      { key: 'RESULT', value: data.diagnosisData?.diagnosisResult ?? '-' },
      {
        key: 'DOCTOR',
        value: data.diagnosisData?.doctorUser?.firstname ?? '-',
      },
      {
        key: 'DIAGNOSE_DATE',
        value: data.diagnosisData?.createdAt
          ? String(date.convertToShowDate(data.diagnosisData?.createdAt))
          : '-',
      },
      { key: 'BMI', value: medTrasaction[0] },
      { key: 'OXYGEN', value: medTrasaction[1] },
      {
        key: 'PRESSURE',
        value:
          medTrasaction[2] !== '-' || medTrasaction[3] !== '-'
            ? `${medTrasaction[3]}/${medTrasaction[2]}`
            : '-',
      },
      {
        key: 'UPLOAD_DATE',
        value: String(date.convertToShowDate(data?.createdAt)) ?? '-',
      },
      { key: 'SUBMITER', value: data.submitUser?.firstname ?? '-' },
      {
        key: 'OPTIONS',
        value: (
          //TODO Change to support data.dataInputFlag in the future
          <Options
            disableViwer={Boolean(!data.dataInputFlag)}
            disableDiagnos={Boolean(data._count?.diagnosisData === 0)}
            healthId={data.umdId}
          />
        ),
      },
    ]
  }

  useEffect(() => {
    ;(async () => {
      const { data, fetching } = medicalData

      if (!fetching && data.getMedicalDatas !== null) {
        setTableData([
          ...data.getMedicalDatas.map((item: any, index: number) =>
            formatData(item, index + 1 + (page - 1) * (isLaptop ? 10 : 5)),
          ),
        ])
      }
    })()
  }, [medicalData, isLaptop])

  useEffect(() => {
    if (!submitCount.fetching) {
      setPageCount(
        Math.ceil(
          submitCount?.data?.getUser?._count?.patientMedicalData /
            (isLaptop ? 10 : 5),
        ) ?? 1,
      )
    }
  }, [submitCount.fetching])

  useEffect(() => {
    executeQueryData()
  }, [page])

  const render = () => {
    if (tableData.length === 0)
      return (
        <div className="flex-col justify-center items-center mt-[10px] p-20 w-full">
          <div className="w-full flex justify-center mb-[20px]">
            <NoDiseaseDataSVG className="w-fit scale-[1.5]" />
          </div>
          <p className="text-gray-main text-center text-[18px]">ไม่มีข้อมูล</p>
        </div>
      )

    if (isLaptop)
      return (
        <div
          className="rounded-[10px] px-[18px] py-[30px] mt-[30px] bg-white-main"
          style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
        >
          <ElementComponent.Table
            col={headerTables.map((item) => {
              if (width >= appSettings.TABLET_BREAKPOINT_WIDTH)
                return item.value

              if (!['BMI', 'OXYGEN', 'PRESSURE'].includes(item.key))
                return item.value
            })}
            row={tableData.map((data: any) => {
              if (Array.isArray(data)) {
                return data.map((item) => {
                  if (width >= appSettings.TABLET_BREAKPOINT_WIDTH)
                    return item.value

                  if (!['BMI', 'OXYGEN', 'PRESSURE'].includes(item.key))
                    return item.value
                })
              }
              return data
            })}
          />

          <div className="mt-[50px] flex justify-center">
            <Pagination
              count={pageCount}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={(_, newValue) => setPage(newValue)}
            />
          </div>
        </div>
      )

    return (
      <div className="mt-[30px]">
        <div className="flex flex-col mt-[30px] gap-[15px]">
          {tableData.map((item: any, index: number) => (
            <HealthDataCard key={`health-card-${index}`} data={item} />
          ))}
        </div>

        <div className="mt-[50px] flex justify-center">
          <Pagination
            count={pageCount}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={(_, newValue) => setPage(newValue)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-[20px] mb-[18px]">
        <Icon icon="ri:shield-cross-line" className="text-[24px]" />
        <p className="text-[20px] font-bold">ข้อมูลสุขภาพ</p>
      </div>

      <div
        className={`flex justify-end flex-col md:flex-row gap-[15px] md:gap-[6px] w-full`}
      >
        <div className="flex-1 md:basis-80 grow-0">
          <ElementComponent.Button
            id="create-medical-data-image"
            text="สร้างข้อมูลสุขภาพโดยการอัพโหลดรูปผลแลป"
            shape="outlined"
            icon="material-symbols:add"
            width="100%"
            height="52px"
            onAction={() => navigate(route.UPLOAD_HEALTH_PAGE_URL)}
          />
        </div>

        <div className="flex-1 md:basis-52 grow-0">
          <ElementComponent.Button
            id="create-medical-data-form"
            text="สร้างข้อมูลสุขภาพ"
            icon="material-symbols:add"
            width="100%"
            height="52px"
            onAction={() => navigate(route.CREATE_HEALTH_PAGE_URL)}
          />
        </div>
      </div>

      {render()}
    </div>
  )
}

export default HealthPage
