import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as Section from './section'

function MainPage() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    if (query.get('section') !== null) {
      const element = document.getElementById(`${query.get('section')}`)
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [location.search])

  return (
    <div
      className="w-full"
      style={{
        background: 'linear-gradient(89.95deg, #004F64 0.05%, #004884 104.41%)',
      }}
    >
      <Section.Banner />
      <div className="mt-[-70px] tablet:mt-[-100px]">
        <Section.UserCard />
        <Section.Consulting />
        <Section.Comunity />
        <Section.AsaNew />
      </div>
    </div>
  )
}

export default MainPage
