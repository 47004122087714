import React from 'react'
import { Typography } from '@mui/material'

import {
  Logo2SVG,
  FacebookIconSVG,
  LineIconSVG,
  TiktokIconSVG,
} from 'assets/svg'

import { urls } from 'settings'

function FooterDesktop() {
  return (
    <div className="h-[170px] w-full absolute bottom-0 flex justify-between items-center px-[20px] laptop:px-[50px] py-[20px] bg-black-light text-white-main">
      <div>
        <Logo2SVG className="mb-[22px] h-[60px] w-[60px]" />
        <Typography variant="body1">© 2022, All rights reserved</Typography>
      </div>
      <div>
        <Typography variant="body1">ติดตามเราได้ทาง Social Media</Typography>
        <div className="flex justify-center gap-[10px] mt-[20px]">
          <a href={urls.LINE_OFFCIAL_GRUOP} target="_blank">
            <LineIconSVG />
          </a>
          <a href={urls.FACEBOOK_GROUP} target="_blank">
            <FacebookIconSVG />
          </a>
          <a href={urls.TIKTOK_GROUP} target="_blank">
            <TiktokIconSVG />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterDesktop
