import React from 'react'
import { Typography, Stack } from '@mui/material'

import { DrBest } from 'assets/picture'
import { BannerProp1SVG, BannerProp2SVG, Logo3SVG } from 'assets/svg'

function Banner() {
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      spacing={{ mobile: '30px' }}
      className="items-center laptop:justify-between px-[40px]"
    >
      <div className="h-full mt-[45px] laptop:mb-[50px] laptop:mt-[0px] laptop:ml-[50px] text-white-main">
        <div className="flex flex-col items-center laptop:text-start text-center laptop:items-start">
          <div className="flex items-center gap-[10px] mb-[35px]">
            <div className="w-[70px] h-[70px]">
              <Logo3SVG className="w-full h-full" />
            </div>
            <div className="text-start">
              <p className="text-[25px] laptop:text-[32px]">DR. ASA</p>
              <p className="text-[8px] laptop:text-[10px]">
                PRIMARY CARE PLATFORM
              </p>
            </div>
          </div>
          <div className="mb-[25px]">
            <p className="text-[32px] desktop:text-[43px]">
              จะรอให้ตัวเองป่วยทำไม
            </p>
            <p className="text-[32px] desktop:text-[43px]">
              ในเมื่อคุณป้องกันโรคของตัวเองได้
            </p>
          </div>
          <p className="text-[20px] desktop:text-[28px]">
            มาออกแบบสุขภาพตัวเองกับหมอประจำตัวคุณด้วย DR.ASA
          </p>
        </div>
      </div>

      <div className="relative w-[350px] laptop:w-[380px] desktop:w-[460px] laptop:pt-0">
        <img src={DrBest} style={{ width: '100%' }} />
        <div className="w-fit z-10 absolute bottom-[60px] tablet:bottom-[100px] left-[-10px] tablet:left-[-100px] laptop:left-[-150px] text-white-main text-right bg-green-main rounded-[10px] py-[12px] px-[31px]">
          <Typography variant="subtitle1">หมอเบสท์</Typography>
          <Typography variant="subtitle2">นายแพทย์ ธนกร ยนต์นิยม</Typography>
        </div>

        <div className="w-[70px] h-[70px] absolute top-[175px] left-[-30px]">
          <BannerProp1SVG className="w-full h-full z-20" />
        </div>
        <div className="w-[70px] h-[70px] absolute top-[20px] left-[40px]">
          <BannerProp2SVG className="w-full h-full z-20" />
        </div>
      </div>
    </Stack>
  )
}

export default Banner
