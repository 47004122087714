import React, { useRef } from 'react'
import { Button } from '@mui/material'
import { Icon } from '@iconify/react'

interface ImageButtonProps {
  id: string
  text?: string
  width?: string
  height?: string
  onChange: Function
}

function ImageButton({
  id,
  text,
  width = '260px',
  height = '210px',
  onChange,
}: ImageButtonProps) {
  const uploadRef = useRef(null)

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(event) => onChange(event)}
      />
      <Button
        id={id}
        variant="outlined"
        sx={{ width: width, height: height }}
        onClick={() => uploadRef.current.click()}
      >
        <div className="flex flex-col justify-center items-center gap-[10px] px-[80px] py-[50px]">
          <Icon icon="ic:baseline-camera-alt" className="text-[25px]" />
          <p>{text ?? 'เพิ่มรูปภาพ'}</p>
        </div>
      </Button>
    </>
  )
}

export default ImageButton
