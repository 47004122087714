import React from 'react'
import { Typography, Stack } from '@mui/material'

import { WoodHand } from 'assets/picture'
import * as SVG from 'assets/svg'
import { urls } from 'settings'

function Consulting() {
  return (
    <Stack
      direction={{ moblie: 'column', laptop: 'row' }}
      className="items-center pt-[10px] laptop:h-[570px] relative"
    >
      <SVG.ConsultingProp2SVG className="absolute top-[20px] left-[20px]" />
      <SVG.ConsultingProp1SVG className="absolute top-[330px] left-[20px]" />

      <div
        id="consulting"
        className="w-full flex justify-center laptop:justify-start p-[50px] laptop:pl-[100px]"
      >
        <div className="text-white-main max-w-[80%] desktop:max-w-[60%]">
          <Stack
            direction={{ mobile: 'column', laptop: 'row' }}
            className="flex items-center gap-[14px] mb-[30px]"
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              ปรึกษาคุณหมอ
            </Typography>
            <a href={urls.LINE_OFFCIAL_GRUOP} target="_blank">
              <SVG.LineIcon4SVG />
            </a>
          </Stack>
          <div className="text-center laptop:text-start">
            <Typography variant="subtitle2">
              เลือกปรึกษาคุณหมอที่ถูกใจ
              รักษาด้วยใจดั่งคนในครอบครัวออกแบบโปรแกรมสุขภาพเฉพาะตัวด้วย
              แพทย์ผู้เชี่ยวชาญนานาสาขา
            </Typography>
          </div>
        </div>
      </div>

      <div className="w-[300px] laptop:w-[480px] laptop:h-full">
        <img src={WoodHand} className="w-full h-full" />
      </div>
    </Stack>
  )
}

export default Consulting
