const LANDING_PAGE_URL = '/'
const PROFILE_PAGE_URL = '/profile'
const CHANGE_PASSWORD = `${PROFILE_PAGE_URL}?change-password=true`

// => Overview Pages
const OVERVIEW_PAGE_URL = '/overview'
const HEALTH_PAGE_URL = '/health-data'
const UPLOAD_HEALTH_PAGE_URL = '/health-data/upload'
const CREATE_HEALTH_PAGE_URL = '/health-data/create'

// => User data Pages
const USER_HEALTH_DATA_PAGE_URL = '/health-data/:umdId'
const DIAGNOSIS_HEALTH_DATA_PAGE_URL = '/health-data/:umdId/diagnosis'

// => Login and Register Pages
const REGISTER_PAGE_URL = '/register'
const CONSULTING_FORM_PAGE_URL = '/register/consulting'
const LOGIN_PAGE_URL = '/login'
const FORGET_PASSWORD_PAGE_URL = '/login/forget'

const EXPECT_PAGE_URL = '*'

export {
  LANDING_PAGE_URL,
  REGISTER_PAGE_URL,
  CONSULTING_FORM_PAGE_URL,
  LOGIN_PAGE_URL,
  CHANGE_PASSWORD,
  FORGET_PASSWORD_PAGE_URL,
  EXPECT_PAGE_URL,
  PROFILE_PAGE_URL,
  OVERVIEW_PAGE_URL,
  HEALTH_PAGE_URL,
  UPLOAD_HEALTH_PAGE_URL,
  CREATE_HEALTH_PAGE_URL,
  USER_HEALTH_DATA_PAGE_URL,
  DIAGNOSIS_HEALTH_DATA_PAGE_URL,
}
