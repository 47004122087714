import React from 'react'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { date } from 'utils'

interface ProgresChartHealthScorceProps {
  data: {
    value: number
    createdAt: Date
  }[]
  title: string
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; circlecolor?: string },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        style={{ color: props.circlecolor }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const mapValue = {
  '0': {
    value: 20,
    color: '#E40009',
  },
  '1': {
    value: 40,
    color: '#F19701',
  },
  '2': {
    value: 60,
    color: '#FBFF01',
  },
  '3': {
    value: 80,
    color: '#CDD801',
  },
  '4': {
    value: 100,
    color: '#69ae33',
  },
}

function ProgresChartHealthScorce({
  data,
  title,
}: ProgresChartHealthScorceProps) {
  return (
    <>
      <div className="font-bold">{title}</div>
      {data.length > 0 ? (
        <div className="mt-[20px]">
          <div className="relative">
            <CircularProgress
              variant="determinate"
              size="100px"
              thickness={5}
              value={100}
              sx={{ position: 'absolute', top: 0, color: '#E7E7E7' }}
            />
            <CircularProgressWithLabel
              size="100px"
              thickness={5}
              circlecolor={mapValue[data[0].value.toPrecision(1)].color}
              value={mapValue[data[0].value.toPrecision(1)].value}
            />
          </div>
          <p className="font-bold text-[12px] mt-[15px]">
            {`วันที่บันทึก : ${date.convertToShowDate(data[0].createdAt)}`}
          </p>
        </div>
      ) : (
        <div className="min-h-[100px] flex items-center justify-center">
          <p className="text-gray-main">ไม่มีข้อมูล</p>
        </div>
      )}
    </>
  )
}

export default ProgresChartHealthScorce
