import { createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql'
import { AuthMutate } from 'services/graphql/authService'
import { authExchange } from '@urql/exchange-auth'
import { makeOperation } from '@urql/core'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'

import { endpoints } from 'settings'
import { general } from 'utils'

const client = (getToken?, setToken?) =>
  createClient({
    url: endpoints.GRAPHQL_DATA_URL,
    requestPolicy: 'cache-and-network',
    exchanges: [
      dedupExchange,
      authExchange({
        getAuth: async ({ authState, mutate }) => {
          // init state
          let refreshToken = general.auth.getRefreshToken()
          if (!authState) {
            let token = getToken as string
            if (token !== '' && refreshToken) {
              return { token, refreshToken }
            }
          }

          if (refreshToken === '') return

          // Add logic refresh token in here for first initial
          const result = await mutate(
            AuthMutate.refreshToken,
            {
              data: { refreshToken: general.auth.getRefreshToken() },
            },
            {
              url: endpoints.GRAPHQL_DATA_URL,
              fetchOptions: {
                headers: {
                  Authorization: `Bearer ${general.auth.getRefreshToken()}`,
                },
              },
            },
          )

          if (result.data?.refreshToken) {
            setToken(result.data.refreshToken.accessToken)

            return {
              token: result.data.refreshToken.accessToken,
              refreshToken: result.data.refreshToken.refreshToken,
            }
          }

          // Logout
          general.auth.logout()
        },
        addAuthToOperation: ({ authState, operation }) => {
          let auth = authState as { token: string; refreshToken: string }

          // the token isn't in the auth state, return the operation without changes
          if (!auth) {
            return operation
          }

          // fetchOptions can be a function (See Client API) but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {}

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${auth.token}`,
              },
            },
          })
        },
        didAuthError: ({ error }) => {
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
          return error.response.status === 401
        },
        willAuthError: ({ authState, operation }) => {
          if (!authState) {
            // Detect our login mutation and let this operation through:
            return !(
              operation.kind === 'mutation' &&
              // Here we find any mutation definition with the "login" field
              operation.query.definitions.some((definition) => {
                return (
                  definition.kind === 'OperationDefinition' &&
                  definition.selectionSet.selections.some((node) => {
                    // The field name is just an example, since signup may also be an exception
                    return node.kind === 'Field' && node.name.value === 'login'
                  })
                )
              })
            )
          }
          return false
        },
      }),
      cacheExchange,
      multipartFetchExchange,
      fetchExchange,
    ],
  })

export default client
