import React, { useState } from 'react'
import { useMutation } from 'urql'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useRecoilState } from 'recoil'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ConnectedFocusError } from 'focus-formik-error'

import { ElementComponent, AlertComponent } from 'components'
import { useAuth } from 'stores/recoil'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import { appSettings } from 'settings'
import { AuthMutate } from 'services/graphql/authService'
import { LoginType } from 'types'

const initialValues: LoginType.LoginDataType = {
  phone: '',
  password: '',
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณากรอกเบอร์โทรศัพท์'),
  password: yup.string().required('กรุณากรอกรหัสผ่าน'),
})

function LoginForm() {
  const { isMobile } = useResponsive()
  const [loginMutation, executeMutation] = useMutation(AuthMutate.login)
  const [, setToken] = useRecoilState(useAuth.selector.selectorToken)
  const [errorModalData, setErrorModalData] = useState({
    title: 'เข้าสู่ระบบไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'เข้าสู่ระบบไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const loginUser = async (values: LoginType.LoginDataType, actions) => {
    try {
      actions.setStatus(undefined)
      await executeMutation({
        data: {
          phone: values.phone,
          password: values.password,
        },
      }).then((res) => {
        if (res.error) {
          const error: any = res.error
          const code: number = error.graphQLErrors[0].originalError.code

          if (code === 401) {
            actions.setStatus({
              phone: 'เบอร์โทรศัพท์ หรือ รหัสผ่านไม่ถูกต้อง',
              password: 'เบอร์โทรศัพท์ หรือ รหัสผ่านไม่ถูกต้อง',
            })
            return
          }

          if (code) {
            setErrorModalData({
              title: 'เข้าสู่ระบบไม่สำเร็จ',
              description: 'กรุณาทดลองใหม่อีกครั้ง',
              open: true,
            })
            return
          }
        }

        general.cookie.setCookie(
          appSettings.TOKEN_KEY,
          res.data.login.refreshToken,
        )
        setToken(res.data.login)
        general.http.redirect()
      })
    } catch (error) {
      setErrorModalData({ ...errorModalData, open: true })
    }
  }

  return (
    <div className="w-full">
      <div className="text-center laptop:text-start flex flex-col gap-[20px]">
        <p
          className="text-[24px] laptop:text-[32px] font-bold"
          id="login-title-message"
        >
          เข้าสู่ระบบ
        </p>
        <p className="text-[18px] laptop:text-[24px]">
          ยินดีต้อนรับสู่ DR. ASA Primary Care Platform
        </p>
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-main mt-[25px] mb-[42px]" />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={loginUser}
      >
        {(formik) => (
          <Form
            onChange={() => {
              formik.setStatus(undefined)
            }}
          >
            <ConnectedFocusError />
            <div className="flex flex-col">
              <ElementComponent.InputBar
                label="เบอร์โทรศัพท์"
                name="phone"
                placeholder="เบอร์โทรศัพท์"
                width={isMobile ? '100%' : '470px'}
                height={isMobile ? '60px' : '60px'}
                style="mb-[30px]"
                value={formik.values.phone}
                errorText={
                  formik.status && formik.status.phone
                    ? formik.status.phone
                    : formik.errors.phone
                }
                error={
                  (formik.status && formik.status.phone) ||
                  (formik.touched.phone && Boolean(formik.errors.phone))
                }
                onChange={(text: string) => formik.setFieldValue('phone', text)}
                frontIcon={
                  <Icon
                    icon="healthicons:phone"
                    className={`text-[20px] ${
                      (formik.status && formik.status.phone) ||
                      (formik.touched.phone && Boolean(formik.errors.phone))
                        ? 'text-red-light'
                        : 'text-trueGray-400'
                    } ml-[12px] mr-[8px]`}
                  />
                }
                isLoading={loginMutation.fetching}
              />
              <ElementComponent.PasswordBar
                name="password"
                label="รหัสผ่าน"
                placeholder="รหัสผ่าน"
                width={isMobile ? '100%' : '470px'}
                height={isMobile ? '60px' : '60px'}
                errorText={
                  formik.status && formik.status.password
                    ? formik.status.password
                    : formik.errors.password
                }
                error={
                  (formik.status && formik.status.password) ||
                  (formik.touched.password && Boolean(formik.errors.password))
                }
                value={formik.values.password}
                onChange={(text: string) =>
                  formik.setFieldValue('password', text)
                }
                icon={
                  <Icon
                    icon="material-symbols:lock"
                    className={`text-[20px] ${
                      (formik.status && formik.status.password) ||
                      (formik.touched.password &&
                        Boolean(formik.errors.password))
                        ? 'text-red-light'
                        : 'text-trueGray-400'
                    }  ml-[12px] mr-[8px]`}
                  />
                }
                isLoading={loginMutation.fetching}
              />
            </div>

            <div className="w-full laptop:w-[470px] mb-[32px] laptop:mb-[48px] mt-[5px] laptop:mt-[20px] text-end">
              <Link
                to="/login/forget"
                className="text-[16px] text-black-lighter cursor-pointer hover:text-blue-main transition-all ease-in-out"
              >
                ลืมรหัสผ่าน ?
              </Link>
            </div>

            <div className="w-full laptop:w-[470px]">
              <ElementComponent.Button
                id="login-submit-button"
                submit
                text="เข้าสู่ระบบ"
                width="100%"
                height={isMobile ? '60px' : '52px'}
              />

              <div className="flex gap-[10px] justify-center mt-[20px]">
                <p>คุณยังไม่ได้เป็นสมาชิก?</p>
                <Link
                  id="register-link"
                  to="/register"
                  className="text-blue-main cursor-pointer transition-all ease-in-out"
                >
                  สมัครสมาชิก
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}

export default LoginForm
